import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { useTheme } from "../../../contents/context/themeContext";
import { useNavigate } from "react-router-dom";

const RippleEndBox = ({ open, setOpen, message }) => {
  const cancelButtonRef = useRef(null);
  const { theme } = useTheme();

  const handleNo = () => {
    setOpen(false);
  };

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 py-4">
                  <div className="sm:flex">
                    <div className="text-center  ">
                      <div className="mb-2 w-full">
                        <p className=" mx-auto text-left text-xl text-gray-700 lg:text-3xl">
                          {message}
                        </p>
                      </div>

                      <div className="flex h-auto flex-col gap-y-2 overflow-y-auto text-left text-xs text-gray-700 lg:h-auto lg:text-base">
                        <div>Kindly go to the webapp for further use.</div>

                        {/* <p className=" text-xs lg:text-sm">
                          Hi, Talk to Shuru  ( Shoorah's most Trusted Guru ) and forget about these negative emotions.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-3xl border border-transparent font-semibold ${theme.shoorah_bg_5} px-6 py-2 text-base  text-white shadow-sm ${theme.shoorah_bg_hover_6} focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={() => (window.location.href = "/")}
                  >
                    Go to Website
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-3xl border border-gray-300 bg-white px-6 py-2 text-base font-semibold  text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      handleNo();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

RippleEndBox.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleNo: PropTypes.func,
  message: PropTypes.string,
};

export default RippleEndBox;
