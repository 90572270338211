import React, { useState } from "react";
import { useTheme } from "../context/themeContext";
import lockSvg from "../../assets/svg/lock.svg";
import unlockSvg from "../../assets/images/Unlock.svg";
import { useAudio } from "../context/audiobar";
import BadgeModal from "./badgeModal";
import { useLocation } from "react-router-dom";

const DetailsSection = ({ badgeInfoData, badgeInfo }) => {
  const { theme } = useTheme();
  const [show, setShow] = useState(false);
  const { audioNav } = useAudio();
  const [infoPopup, setInfoPopup] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const badge = searchParams.get("badge");

  let info = badgeInfo && Object.entries(badgeInfo);

  return (
    <div
      className={`relative  w-full ${audioNav && `mb-[10rem]`} mb-[1rem] mt-[3rem] flex flex-col gap-[1.5rem] md:mb-[5rem] md:gap-[3rem]`}
    >
      {badgeInfoData &&
        Object.keys(badgeInfoData).map((key) => (
          <div
            key={key}
            className="mx-auto flex w-[90%] flex-col gap-3 rounded-3xl border bg-white py-[1rem] px-[0.7rem] sm:gap-5 sm:py-[2rem] sm:px-[1.5rem]"
          >
            <div className=" flex gap-3 text-sm sm:text-xl">
              <p className="P22Mackinac text-lg sm:text-xl">{key}</p>
              <span
                onClick={() => {
                  setShow(true);
                  setInfoPopup(key);
                }}
                className="cursor-pointer"
              >
                <div className="h-[1.4rem] w-[1.4rem] cursor-pointer  hover:scale-110 md:h-[1.8rem] md:w-[1.8rem]">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      transform="matrix(-1 0 0 1 22 2)"
                      fill={theme.shoorah_2}
                      stroke={theme.shoorah_7}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12 12L12 18"
                      stroke={theme.shoorah_7}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12 7L12 6"
                      stroke={theme.shoorah_7}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </span>
            </div>

            {badgeInfoData[key].map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-between rounded-3xl border py-4 px-5 md:py-8 md:px-3 ${item.isUnlocked ? `border-[#12B76A] bg-[#fff]` : `border-gray-300 bg-gray-100`}`}
              >
                <div className="flex flex-col gap-2">
                  <p className="P22Mackinac text-sm sm:text-lg">{item.title}</p>
                  {index != 1 && badge != 1 ? (
                    <p className="P22Mackinac text-sm text-gray-500 sm:text-lg">
                      {item.description}{" "}
                      {item.counts > item.limit
                        ? "(" + item.limit + "/" + item.limit + ")"
                        : "(" + item.counts + "/" + item.limit + ")"}
                    </p>
                  ) : (
                    <p className="P22Mackinac text-sm text-gray-500 sm:text-lg">
                      {item.description}{" "}
                      {item.counts > item.limit
                        ? "(" + item.limit + "/" + item.limit + ")"
                        : "(" + item.counts + "/" + item.limit + ")"}
                    </p>
                  )}
                </div>
                <div className="mr-[1rem] h-[1.5rem] w-[1.5rem] md:h-[2rem] md:w-[2rem]">
                  <img
                    src={item.isUnlocked ? unlockSvg : lockSvg}
                    className="h-full w-full rounded-3xl"
                  />
                </div>
              </div>
            ))}
          </div>
        ))}

      {!badgeInfoData && (
        <div className="P22Mackinac mx-auto mt-[3rem] flex h-full  w-full w-full items-center justify-center text-center text-lg md:mt-[8rem] md:w-[40rem] md:text-3xl">
          Please explore more about the web and earn 3 or more diamond badges to
          become a proud Shoorah Guru User.
        </div>
      )}

      <BadgeModal
        open={show}
        setOpen={setShow}
        title={infoPopup}
        description={info && badgeInfo[infoPopup]}
      />
    </div>
  );
};

export default DetailsSection;
