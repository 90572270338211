import React, { Fragment, useState } from "react";
import SosModal from "./sosModal";
import { Api } from "../../api";

const SOSButton = () => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
    Api.sosClick(1)
      .then((res) => {
        if (res.data.meta.code == 1) {
          console.log("SOS");
        } else {
          console.log("SOS");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <div
        onClick={handleClick}
        className="P22Mackinac fixed right-[1rem] bottom-[2rem] z-10 flex h-[3rem] w-[3rem] cursor-pointer items-center justify-center rounded-full border border-transparent bg-[#F04438] text-sm font-[1000] text-white hover:scale-110 sm:h-[4.5rem] sm:w-[4.5rem] sm:text-xl"
      >
        Help
        <SosModal
          open={show}
          setOpen={setShow}
          message={`Call Suicide Prevention Helpline UK`}
        />
      </div>
    </Fragment>
  );
};

export default SOSButton;
