import React, { useEffect, useState } from "react";
import ShuruHome from "./shuruHome/shuruHome";
import ShuruSelection from "./shuruHome/shuruSelection";
import ChatScreen from "./shuruHome/chatScreen";
import DisclaimerPopUp from "../component/common/modals/DisclaimerPopUp";
import { useNavigate } from "react-router-dom";
import { useAudio } from "../contents/context/audiobar";
import { useAuth } from "../contents/context/user";
import { Api } from "../api";
import { getLocalStorageItem, setLocalStorageItem } from "../utils/helper";
import { useCookies } from "react-cookie";
import moment from "moment";
import RippleBox from "../component/common/modals/RippleBox";
import RippleEndBox from "../component/common/modals/RippleEndBox";

const RippleShuru = () => {
  const [tab, setTab] = useState(1);
  const [item, setItem] = useState("content");
  const [moods, setMoods] = useState([]);
  const [currentMood, setCurrentMood] = useState([]);
  const [userData, setUserData] = useState(null);
  const { audioNav } = useAudio();
  const [cookies, setCookie] = useCookies(["anonUser"]);
  let updated = getLocalStorageItem("updated");
  let sessionBeginTime = getLocalStorageItem("sessionTime");

  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [show, setShow] = useState(false);
  const [showEndBox, setShowEndBox] = useState(false);

  const rippleShuru = () => {
    Api.rippleUser().then((res) => {
      if (res.data?.code == 1) {
        setUserData(res.data?.userId);
        setLocalStorageItem("anonId", res.data?.userId);
        setLocalStorageItem("updated", true);

        if (!updated) {
          setLocalStorageItem("sessionTime", new Date());
          setCookie("userId", res.data?.userId, { path: "/" });
          setCookie("sessionTime", new Date(), { path: "/" });
        }
      } else {
        window.location.href = "/";
      }
    });
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return setSessionStartTime(decodeURIComponent(cookie[1]));
      }
    }
    return setSessionStartTime(null);
  };

  useEffect(() => {
    if (sessionStartTime) {
      const interval = setInterval(() => {
        const currentDate = moment();
        let date = sessionStartTime;
        const dateString = date.replace(/"/g, ""); // Remove the quotes
        const dateObject = new Date(dateString);
        const targetDate = moment(dateObject);

        // Check if the parsed date is valid
        if (!targetDate.isValid()) {
          // console.error('Invalid date format');
          return;
        }
        const differenceInSeconds = targetDate.diff(currentDate, "seconds");

        if (differenceInSeconds == -60) {
          setShow(true);
        }
        if (differenceInSeconds <= -86400) {
          setShowEndBox(true);
          // clearInterval(interval);
        }
        // console.log('Check', differenceInSeconds)
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, [sessionStartTime]);

  useEffect(() => {
    rippleShuru();
    getCookie("sessionTime");
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("ripple-profile")) {
      setTab(2);
    } else {
      setTab(1);
    }
  }, []);

  useEffect(() => {
    getMood();
  }, []);

  useEffect(() => {
    getCurrentMood();
  }, [item]);

  const getCurrentMood = () => {
    if (moods.length) {
      const current = moods.find((i) => {
        return i.name.toLowerCase() == item.toLowerCase();
      });
      setCurrentMood(current);
    }
  };

  const getMood = () => {
    Api.rippleGetMood()
      .then((res) => {
        setMoods(res.data.data);
      })
      .catch(() => {});
  };

  return (
    <div
      className={`h-screen overflow-y-auto overflow-x-hidden lg:pb-0 ${audioNav && `mb-[5rem]`}`}
    >
      {tab == 1 ? (
        <ShuruHome
          userId={userData}
          setShowEndBox={setShowEndBox}
          setShow={setShow}
          tab={tab}
          setTab={setTab}
        />
      ) : tab == 2 ? (
        <ShuruSelection
          userId={userData}
          item={item}
          setItem={setItem}
          tab={tab}
          setTab={setTab}
          setShow={setShow}
          setShowEndBox={setShowEndBox}
        />
      ) : (
        <ChatScreen
          userId={userData}
          currentMood={currentMood}
          tab={tab}
          item={item}
          setItem={setItem}
          setShow={setShow}
          setShowEndBox={setShowEndBox}
        />
      )}
      <RippleBox
        open={show}
        setOpen={setShow}
        message={"Shoorah’s Support Tool!"}
      />
      <RippleEndBox
        open={showEndBox}
        setOpen={setShowEndBox}
        message={"Shoorah"}
      />
    </div>
  );
};

export default RippleShuru;
