import React, { useState } from "react";
import Header from "../../header";
import { useTheme } from "../../../context/themeContext";
import ProfessionalMoodsHome from "./professionalMoods";
import Moods from "./moods";
import downloadBtn from "../../../../assets/svg/downloadSvg.svg";
import DownloadMoodReport from "./downloadMoodReport";
import { Api } from "../../../../api";
import { useNavigate } from "react-router-dom";

const MoodsHome = () => {
  const { theme } = useTheme();
  const [tab, setTab] = useState(0);
  const [showMoodDownload, setShowMoodDownload] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    Api.getCurrentPlan().then((res) => {
      if (res.data.meta.code == 1) {
        if (
          res?.data?.data?.accountType != "SUBSCRIBED" &&
          res?.data?.data?.isUnderTrial == false
        ) {
          navigate("/subscription", { replace: true });
        } else {
          setShowMoodDownload(true);
        }
      }
    });
  };

  return (
    <div className="mx-auto w-screen overflow-x-hidden  px-4 xl:px-0">
      <div className="relative flex justify-center">
        <Header
          title={`Moods & Emotions`}
          backUrl={`/home`}
          downloadMood={true}
          goBack={true}
          hide={true}
          home={true}
        />
        <button
          onClick={handleClick}
          className=" ease absolute  right-[-2px] top-1 z-10 my-[1rem] h-[1.5rem] w-[1.5rem] outline-none duration-500 hover:translate-x-1 hover:scale-110 sm:h-[2rem] sm:w-[4rem] md:right-4  md:top-4 xl:right-[4rem] xl:top-[1rem]"
        >
          <img src={downloadBtn} className="h-full w-full" />
        </button>
      </div>

      <div className="mt-[1.5rem] flex w-full justify-center md:mt-[2rem]">
        <div className=" flex w-full items-center justify-center gap-4 xl:gap-[5rem]">
          <div
            onClick={() => setTab(0)}
            className={
              tab == 0
                ? `rounded-[3rem] text-[14px] sm:text-[14px] md:text-[14px] lg:text-xl ${theme.shoorah_bg_1}  flex  items-center justify-center py-3  px-8 md:px-12 lg:px-16 ${theme.shoorah_text_5} P22Mackinac ease cursor-pointer duration-1000`
                : `rounded-[3rem] text-[14px] sm:text-[14px] md:text-[14px] lg:text-xl ${theme.inactiveToggleText} P22Mackinac ease flex  cursor-pointer  items-center justify-center py-3 py-3 px-8 duration-1000 md:px-12 lg:px-16`
            }
          >
            <span className="hidden xl:block">Personal Moods</span>
            <span className="block xl:hidden">Personal</span>
          </div>
          <div
            onClick={() => setTab(1)}
            className={
              tab == 1
                ? `rounded-[3rem] text-[14px] sm:text-[14px] md:text-[14px] lg:text-xl ${theme.shoorah_bg_1}  flex  items-center justify-center py-3  px-8 md:px-12 lg:px-16 ${theme.shoorah_text_5} P22Mackinac ease cursor-pointer duration-1000`
                : `rounded-[3rem] text-[14px] sm:text-[14px] md:text-[14px] lg:text-xl ${theme.inactiveToggleText} P22Mackinac ease flex  cursor-pointer  items-center justify-center py-3 py-3 px-8 duration-1000 md:px-12 lg:px-16`
            }
          >
            <span className="hidden xl:block">Professional Moods</span>
            <span className="block xl:hidden">Professional</span>
          </div>
        </div>
      </div>

      {tab == 0 ? <Moods /> : <ProfessionalMoodsHome />}
      <DownloadMoodReport
        open={showMoodDownload}
        setOpen={setShowMoodDownload}
      />
    </div>
  );
};

export default MoodsHome;
