import React, { Fragment, useEffect, useRef, useState } from "react";
import Setting from "./setting";
import Lottie from "lottie-react";
import { Api } from "../../api";
import { Link, useNavigate } from "react-router-dom";
import ConfirmPopup from "../../component/common/modals/ConfirmPopup";
import { TypeAnimation } from "react-type-animation";
import { useTheme } from "../../contents/context/themeContext";
import { useAudio } from "../../contents/context/audiobar";
import moment from "moment";

const ChatScreen = ({
  tab,
  item,
  currentMood,
  setShowEndBox,
  setShow,
  userId,
  setItem,
}) => {
  const { theme } = useTheme();
  const [mood, setMood] = useState(theme.shuruContent);
  const [chat, setChat] = useState([]);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const [askCount, setAsk] = useState(0);
  const [loader, setLoader] = useState(false);
  const chatContainerRef = useRef(null);
  const { audioNav } = useAudio();
  const [time, setTime] = useState();
  const [waitMessage, setWaitMessage] = useState(false);

  function getTimeBaseDay() {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 7 && currentHour < 12) {
      return "morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "afternoon";
    } else if (currentHour >= 17 && currentHour < 20) {
      return "evening";
    } else {
      return "latenight";
    }
  }

  const getMood = () => {
    switch (currentMood?.name) {
      case "angry":
        setMood(theme.shuruStrokeAngry);
        break;
      case "content":
        setMood(theme.shuruStrokeContent);
        break;
      case "anxious":
        setMood(theme.shuruStrokeAnxious);
        break;
      case "surprised":
        setMood(theme.shuruStrokeSurprised);
        break;
      case "stress":
        setMood(theme.shuruStress);
        break;
      case "sad":
        setMood(theme.shuruStrokeSad);
        break;
      case "happy":
        setMood(theme.shuruStrokeHappy);
        break;
      case "tired":
        setMood(theme.shuruStrokeTired);
        break;
      case "excited":
        setMood(theme.shuruStrokeExcited);
        break;
      default:
        setMood(theme.shuruStrokeContent);
        break;
    }
  };

  useEffect(() => {
    getMood();
    setChatMood();
  }, [item]);

  const setChatMood = () => {
    const payload = {
      mood_id: currentMood?.id,
      time: getTimeBaseDay(),
      user_id: userId,
    };
    Api.rippleSetMood(payload).then((res) => {
      setChat([{ message: res.data.data, id: 1, user: "Bot" }]);
    });
  };

  const handleAsk = () => {
    if (!text.replace(/\s/g, "").length) {
      setText("");
      return;
    }
    const chats = [...chat];
    chats.push({ message: text, id: 2, user: "User" });
    setChat(chats);
    setText("");

    setTimeout(() => {
      setWaitMessage(true);
      const chats = [...chat];
      chats.push({ message: text, id: 2, user: "User" });
      chats.push({ message: "", id: 1, user: "Bot", isLoading: true });
      setChat(chats);
    }, 1000);

    setTimeout(() => {
      const payload = {
        isSessionStart: true,
        message: text,
        to: "bot",
        userId,
      };
      Api.rippleAsk(payload).then((res) => {
        // setChat([{ message: res.data.data, id: 1,user:'Bot' }])
        const chats = [...chat];
        if (chats[chats.length - 1].isLoading) {
          chats.pop();
        }

        chats.push({ message: text, id: 2, user: "User" });
        chats.push({ message: res.data.data, id: 1, user: "Bot" });
        setChat(chats);
        setText("");
        setWaitMessage(false);
      });
    }, 2000);
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [chat]);

  const [sessionStartTime, setSessionStartTime] = useState(null);

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return setSessionStartTime(decodeURIComponent(cookie[1]));
      }
    }
    return setSessionStartTime(null);
  };

  useEffect(() => {
    getCookie("sessionTime");
  }, []);

  useEffect(() => {
    if (sessionStartTime) {
      const interval = setInterval(() => {
        const currentDate = moment();
        let date = sessionStartTime;
        const dateString = date.replace(/"/g, ""); // Remove the quotes
        const dateObject = new Date(dateString);
        const targetDate = moment(dateObject);

        // Check if the parsed date is valid
        if (!targetDate.isValid()) {
          // console.error('Invalid date format');
          return;
        }
        const differenceInSeconds = targetDate.diff(currentDate, "seconds");

        if (differenceInSeconds == -60) {
          setShow(true);
        }
        if (differenceInSeconds <= -86400) {
          setShowEndBox(true);
          // clearInterval(interval);
        }
        // console.log('Check', differenceInSeconds)
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, [sessionStartTime]);

  return (
    <div
      className={`relative flex h-screen w-full flex-grow flex-col  gap-8  `}
    >
      {/* header div */}
      <div className="relative h-36 w-screen lg:mb-8 lg:h-[13rem] xl:mb-20">
        <div
          // style={{ borderRadius: "0 0 50% 50% /0 0 90% 90%" }}
          className={` absolute inset-0 h-20 w-screen scale-110 rounded-b-[50%] text-white lg:h-32  ${theme.shoorah_bg_5} z-10`}
        >
          <div className="flex h-full w-full items-center justify-center">
            <div className="relative h-full w-screen ">
              <div className=" absolute bottom-0 left-1/2 h-28 w-28 -translate-x-1/2 translate-y-1/2 self-end lg:h-[12rem] lg:w-[12rem]">
                <img src={mood} alt="mood" className="h-full w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex h-[100%] w-[100%] flex-grow justify-center overflow-y-auto rounded-3xl    px-4  lg:px-0 ">
        <div
          className={`border-3 relative flex w-full flex-col items-center justify-around  overflow-hidden rounded-t-[3rem] border border px-4 pb-2 xl:w-[90%] xl:px-0 ${theme.shoorah_border_2} ${theme.shoorah_bg_2}`}
        >
          <div
            ref={chatContainerRef}
            className={`scrollbar relative flex h-full w-[100%] flex-col gap-[1rem] overflow-y-scroll py-2 xl:px-[3rem] `}
          >
            {chat.map((i) => (
              <Fragment key={i.id} className="relative text-sm">
                {i.id == 1 ? (
                  <div
                    style={{ borderRadius: "1.5rem 3rem 3rem 4rem" }}
                    className="w-full bg-white  p-3 px-8 text-gray-500 xl:w-[60%]"
                  >
                    <p className="">
                      {i.isLoading ? (
                        <p className="flex animate-pulse gap-3">
                          <div
                            className={`h-3 w-3 ${theme.shoorah_bg_5} rounded-full`}
                          ></div>
                          <div
                            className={`h-3 w-3 ${theme.shoorah_bg_4} rounded-full`}
                          ></div>
                          <div
                            className={`h-3 w-3 ${theme.shoorah_bg_5} rounded-full`}
                          ></div>
                        </p>
                      ) : (
                        <TypeAnimation
                          style={{ whiteSpace: "pre-line", display: "block" }}
                          sequence={[
                            i.message, // actual line-break inside string literal also gets animated in new line, but ensure there are no leading spaces
                            1000,
                          ]}
                          repeat={0}
                          speed={80}
                        />
                      )}
                    </p>
                  </div>
                ) : (
                  <p
                    style={{ borderRadius: "3rem 4rem 1.5rem 3rem" }}
                    className={` max-w-full self-end p-3 px-8 text-white xl:w-[60%] ${theme.shoorah_bg_4}`}
                  >
                    {i.message}
                  </p>
                )}
              </Fragment>
            ))}
          </div>

          <div
            className={`w-[100%]  ${
              audioNav && `mb-[8rem]`
            } flex items-center justify-around gap-[1rem] self-center xl:px-[3rem]`}
          >
            <input
              onKeyDown={(e) => {
                if (e.key === "Enter" && text.trim() !== "") {
                  handleAsk();
                }
              }}
              disabled={waitMessage}
              placeholder="Type a message..."
              onChange={(e) => setText(e.target.value)}
              value={text}
              className="w-[90%] rounded-[3rem] p-3 py-4 pl-8 outline-none"
            />

            <button
              onClick={handleAsk}
              disabled={waitMessage}
              className="flex h-12 w-12 items-center justify-center rounded-full bg-white hover:bg-gray-300 "
            >
              <svg
                className="h-7 w-7"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 122.88 103.44"
                fill={theme.shoorah_7}
              >
                <g>
                  <path d="M69.49,102.77L49.8,84.04l-20.23,18.27c-0.45,0.49-1.09,0.79-1.8,0.79c-1.35,0-2.44-1.09-2.44-2.44V60.77L0.76,37.41 c-0.98-0.93-1.01-2.47-0.09-3.45c0.31-0.33,0.7-0.55,1.11-0.67l0,0l118-33.2c1.3-0.36,2.64,0.39,3.01,1.69 c0.19,0.66,0.08,1.34-0.24,1.89l-49.2,98.42c-0.6,1.2-2.06,1.69-3.26,1.09C69.86,103.07,69.66,102.93,69.49,102.77L69.49,102.77 L69.49,102.77z M46.26,80.68L30.21,65.42v29.76L46.26,80.68L46.26,80.68z M28.15,56.73l76.32-47.26L7.22,36.83L28.15,56.73 L28.15,56.73z M114.43,9.03L31.79,60.19l38.67,36.78L114.43,9.03L114.43,9.03z" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
