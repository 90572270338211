import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { useTheme } from "../../../contents/context/themeContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contents/context/user";
import CommonInput from "../Input/CommonInput";
import rippleValidation from "../../../validation/RippleValidation";
import { Api } from "../../../api";
import {
  errorToast,
  getLocalStorageItem,
  successToast,
} from "../../../utils/helper";

const RippleBox = ({ open, setOpen, message }) => {
  const cancelButtonRef = useRef(null);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  let anonId = getLocalStorageItem("anonId");

  const handleNo = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleSubmit = () => {
    let payload = {
      email,
      anonId,
    };

    const { errors, isValid } = rippleValidation(payload);

    if (isValid) {
      Api.rippleAddUser(payload).then((res) => {
        if (res?.data?.code == 1) {
          setOpen(false);
          setEmail("");
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      });
    } else {
      setError(errors);
    }
  };

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 py-4">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center  ">
                      <div className="mb-2 w-full">
                        <p className=" w-full text-left text-xl text-gray-700 lg:text-center lg:text-4xl">
                          {message}
                        </p>
                      </div>

                      <div className="flex h-[35vh] flex-col gap-y-2 overflow-y-auto text-left text-xs text-gray-700 lg:h-auto lg:text-base">
                        <div>
                          No matter what has happened, or how bad it may seem
                          today, I promise you will be ok, you are not alone,
                          and it will be better. You maybe hurting and that is
                          ok — it’s not a sign of weakness to ask for help. It’s
                          a sign of strength. The bravest thing I ever did was
                          continuing my life when I wanted to not be here
                          anymore, you are like me, brave and strong.
                        </div>

                        <p className=" text-xs lg:text-sm">
                          Lets have a chat, I’m Shuru ( Shoorah's most Trusted
                          Guru )
                        </p>
                        <div className="text-sm">
                          <CommonInput
                            id="email"
                            name="email"
                            type="text"
                            value={email}
                            onChange={handleChange}
                            label="Email"
                            error={error.email}
                            classNames="px-4 py-2 sm:px-5 sm:py-3"
                            placeholder="Enter your email"
                            isRequired
                          />
                          <p className=" mt-3 text-xs lg:text-sm">
                            Please provide a email so we can send you a free
                            smart link, for you to continue your chats with
                            Shuru for the next 30 days. (Hit cancel to just chat
                            now)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-3xl border border-transparent font-semibold ${theme.shoorah_bg_5} px-6 py-2 text-base  text-white shadow-sm ${theme.shoorah_bg_hover_6} focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={handleSubmit}
                  >
                    Agree and Continue
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-3xl border border-gray-300 bg-white px-6 py-2 text-base font-semibold  text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      handleNo();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

RippleBox.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleNo: PropTypes.func,
  message: PropTypes.string,
};

export default RippleBox;
