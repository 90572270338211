import React, { Fragment, useEffect, useState } from "react";
import { Api } from "../../api";
import { errorToast } from "../../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import ShuruAnimateCircle from "./shuruAnimateCircle";
import ConfirmPopup from "../../component/common/modals/ConfirmPopup";
import moment from "moment";

import messageLogo from "../../assets/images/message.svg";
import { useTheme } from "../../contents/context/themeContext";
const ShuruSelection = ({
  tab,
  setTab,
  userId,
  setShowEndBox,
  setShow,
  setItem,
}) => {
  const { theme } = useTheme();
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [sessionStartTime, setSessionStartTime] = useState(null);

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return setSessionStartTime(decodeURIComponent(cookie[1]));
      }
    }
    return setSessionStartTime(null);
  };

  useEffect(() => {
    getCookie("sessionTime");
  }, []);

  useEffect(() => {
    if (sessionStartTime) {
      const interval = setInterval(() => {
        const currentDate = moment();
        let date = sessionStartTime;
        const dateString = date.replace(/"/g, ""); // Remove the quotes
        const dateObject = new Date(dateString);
        const targetDate = moment(dateObject);

        // Check if the parsed date is valid
        if (!targetDate.isValid()) {
          // console.error('Invalid date format');
          return;
        }
        const differenceInSeconds = targetDate.diff(currentDate, "seconds");

        if (differenceInSeconds == -60) {
          setShow(true);
        }
        if (differenceInSeconds <= -86400) {
          setShowEndBox(true);
          // clearInterval(interval);
        }
        // console.log('Check', differenceInSeconds)
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, [sessionStartTime]);

  return (
    <div
      className={`relative flex h-screen w-full  grow flex-col items-center gap-y-4  overflow-hidden`}
    >
      <div className="mt-6 flex h-auto w-full scale-90 flex-col  items-center justify-center xl:mt-0">
        <ShuruAnimateCircle setItem={setItem} />
      </div>

      <div
        // style={{ borderRadius: "50% 50% 0 0/90% 90% 0 0" }}
        className={` flex  h-full w-[130%] grow flex-col items-center justify-start rounded-t-[50%]  py-8 text-white ${theme.shoorah_bg_5}`}
      >
        <div className="flex w-screen flex-col gap-6 px-4 xl:w-[50%] xl:px-0">
          <p className="P22Mackinac text-center text-3xl font-medium xl:text-5xl">
            Before We Start...
          </p>
          <p className="mx-auto w-full text-center text-base  lg:text-xl ">
            if you're open to sharing please <br /> state your current mood.
          </p>
          {/* <p className="text-center text-lg">
            If not you can start chat below:
          </p> */}
          <div className="flex items-center justify-center gap-5">
            <button
              onClick={() => {
                setTab(3);
              }}
              className="flex items-center justify-center gap-3 outline-none"
            >
              <div className="flex h-10 w-10 items-center justify-center  rounded-full  border-2 p-[0.4rem] xl:h-10 xl:w-10 xl:border-[3px]">
                <img
                  className="h-full w-full"
                  id="chat"
                  src={messageLogo}
                  alt="message"
                />
              </div>
              <p className="P22Mackinac text-center text-lg font-medium">
                Start Chat
              </p>
              <p className="rotate-180">
                <svg
                  height="24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <g id="arrow-left-1--arrow-keyboard-left">
                    <path
                      id="Vector 2"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m1 7 12 0"
                    ></path>
                    <path
                      id="Vector"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.996 3C3 4 2 5 1 7c1 2 2 3 3.996 4"
                    ></path>
                  </g>
                </svg>
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShuruSelection;
