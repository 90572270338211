import s_grad_hard from "../feathers/shoorah/Shoorah Heavy Gradient.png";
import s_grad_light from "../feathers/shoorah/Shoorah Light Gradient.png";
import s_grad_medium from "../feathers/shoorah/Shoorah Medium Gradient.png";
import s_grad_feather from "../feathers/shoorah/Shoorah Feather Gradient.png";
import personalize from "../heroNavButtons/shoorah/personalize.svg";
import notify from "../heroNavButtons/shoorah/notify.svg";
import homeActive from "../shoorahSidebarIcons/shoorah/homeActive.svg";
import homeInactive from "../shoorahSidebarIcons/shoorah/homeInactive.svg";
import journalActive from "../shoorahSidebarIcons/shoorah/journalActive.svg";
import journalInactive from "../shoorahSidebarIcons/shoorah/journalInactive.svg";
import ritualActive from "../shoorahSidebarIcons/shoorah/ritualsActive.svg";
import ritualsInactive from "../shoorahSidebarIcons/shoorah/ritualsInactive.svg";
import restoreActive from "../shoorahSidebarIcons/shoorah/restoreActive.svg";
import restoreInactive from "../shoorahSidebarIcons/shoorah/restoreInactive.svg";
import exploreActive from "../shoorahSidebarIcons/shoorah/exploreActive.svg";
import exploreInactive from "../shoorahSidebarIcons/shoorah/exploreInactive.svg";
import titleIcon from "../shoorahTextBoxes/shoorah/title.svg";
import descriptionIcon from "../shoorahTextBoxes/shoorah/description.svg";
import shoorahCircle from "../../affirmationBg/shoorah.png";
import shuruContent from "../../../shuru_characters/shoorah/shuru_shoorah_content.svg";
import shuruAngry from "../../../shuru_characters/shoorah/shuru_shoorah_angry.svg";
import shuruAnxious from "../../../shuru_characters/shoorah/shuru_shoorah_anxious.svg";
import shuruExcited from "../../../shuru_characters/shoorah/shuru_shoorah_excited.svg";
import shuruHappy from "../../../shuru_characters/shoorah/shuru_shoorah_happy.svg";
import shuruSad from "../../../shuru_characters/shoorah/shuru_shoorah_sad.svg";
import shuruStressed from "../../chatbotShuru/shoorah/shuru_shoorah_fearful.svg";
import shuruSurprised from "../../../shuru_characters/shoorah/shuru_shoorah_suprised.svg";
import shuruTired from "../../../shuru_characters/shoorah/shuru_shoorah_tired.svg";
import shuruStrokeContent from "../../chatbotShuru/shoorah/shuru_shoorah_content.svg";
import shuruMain from "../../chatbotShuru/shoorah/shuru_shoorah_main.svg";
import shuruStrokeAngry from "../../chatbotShuru/shoorah/shuru_shoorah_angry.svg";
import shuruStrokeAnxious from "../../chatbotShuru/shoorah/shuru_shoorah_anxious.svg";
import shuruStrokeExcited from "../../chatbotShuru/shoorah/shuru_shoorah_excited.svg";
import shuruStrokeHappy from "../../chatbotShuru/shoorah/shuru_shoorah_happy.svg";
import shuruStrokeSad from "../../chatbotShuru/shoorah/shuru_shoorah_sad.svg";
import shuruStrokeSurprised from "../../chatbotShuru/shoorah/shuru_shoorah_suprised.svg";
import shuruStrokeTired from "../../chatbotShuru/shoorah/shuru_shoorah_tired.svg";
import shuruProfile from "../../../shuru_characters/shoorah/my_profile_shuru.svg";
import shuruLeave from "../../../shuru_characters/shoorah/leave_shuru.svg";
import shoorahContentLotie from "../../../shuru_lottie/shoorah/prayer_head_animation.json";
import shoorahAngryLotie from "../../../shuru_lottie/shoorah/angry_head_animation.json";
import shoorahWorriedLotie from "../../../shuru_lottie/shoorah/worried_head_animation.json";
import shoorahAnxiousLotie from "../../../shuru_lottie/shoorah/anxious_head_animation.json";
import shoorahCryLotie from "../../../shuru_lottie/shoorah/cry_head_animation.json";
import shoorahExcitedLotie from "../../../shuru_lottie/shoorah/excited_head_animation.json";
import shoorahHappyLotie from "../../../shuru_lottie/shoorah/happy_head_animation.json";
import shoorahSleepLotie from "../../../shuru_lottie/shoorah/sleep_head_animation.json";
import shoorahSurpriseLotie from "../../../shuru_lottie/shoorah/surprise_head_animation.json";

import boxShape from "../shoorahShapes/shoorah/boxShape.svg";
import circleShape from "../shoorahShapes/shoorah/circleShape.svg";
import starShape from "../shoorahShapes/shoorah/starShape.svg";
import edgeBox from "../shoorahShapes/shoorah/sharpBoxShape.svg";
import addImageIcon from "../heroNavButtons/shoorah/addImageIcon.svg";

import l_grad_hard from "../feathers/land/land_heavy_gradient.svg";
import l_grad_light from "../feathers/land/Land Light Gradient.png";
import l_grad_feather from "../feathers/land/Land Feather Gradient.png";
import l_grad_medium from "../feathers/land/Land Medium Gradient.png";
import l_personalize from "../heroNavButtons/land/personalize.svg";
import l_notify from "../heroNavButtons/land/notify.svg";
import l_homeActive from "../shoorahSidebarIcons/land/homeActive.svg";
import l_homeInactive from "../shoorahSidebarIcons/land/homeInactive.svg";
import l_journalActive from "../shoorahSidebarIcons/land/journalActive.svg";
import l_journalInactive from "../shoorahSidebarIcons/land/journalInactive.svg";
import l_ritualActive from "../shoorahSidebarIcons/land/ritualsActive.svg";
import l_ritualsInactive from "../shoorahSidebarIcons/land/ritualsInactive.svg";
import l_restoreActive from "../shoorahSidebarIcons/land/restoreActive.svg";
import l_restoreInactive from "../shoorahSidebarIcons/land/restoreInactive.svg";
import l_exploreActive from "../shoorahSidebarIcons/land/exploreActive.svg";
import l_exploreInactive from "../shoorahSidebarIcons/land/exploreInactive.svg";
import l_titleIcon from "../shoorahTextBoxes/land/title.svg";
import l_descriptionIcon from "../shoorahTextBoxes/land/description.svg";
import l_addImageIcon from "../heroNavButtons/land/addImageIcon.svg";
import l_shoorahCircle from "../../affirmationBg/land.png";
import l_shuruContent from "../../../shuru_characters/land/shuru_land_content.svg";
import l_shuruAngry from "../../../shuru_characters/land/shuru_land_angry.svg";
import l_shuruAnxious from "../../../shuru_characters/land/shuru_land_anxious.svg";
import l_shuruExcited from "../../../shuru_characters/land/shuru_land_excited.svg";
import l_shuruHappy from "../../../shuru_characters/land/shuru_land_happy.svg";
import l_shuruSad from "../../../shuru_characters/land/shuru_land_sad.svg";
import l_shuruStressed from "../../chatbotShuru/land/shuru_land_fearful.svg";
import l_shuruSurprised from "../../../shuru_characters/land/shuru_land_suprised.svg";
import l_shuruTired from "../../../shuru_characters/land/shuru_land_tired.svg";
import l_shuruStrokeContent from "../../chatbotShuru/land/shuru_land_content.svg";
import l_shuruStrokeAngry from "../../chatbotShuru/land/shuru_land_angry.svg";
import l_shuruStrokeAnxious from "../../chatbotShuru/land/shuru_land_anxious.svg";
import l_shuruStrokeExcited from "../../chatbotShuru/land/shuru_land_excited.svg";
import l_shuruStrokeHappy from "../../chatbotShuru/land/shuru_land_happy.svg";
import l_shuruStrokeSad from "../../chatbotShuru/land/shuru_land_sad.svg";
import l_shuruStrokeSurprised from "../../chatbotShuru/land/shuru_land_suprised.svg";
import l_shuruStrokeTired from "../../chatbotShuru/land/shuru_land_tired.svg";
import l_shuruProfile from "../../../shuru_characters/land/my_profile_shuru.svg";
import l_shuruLeave from "../../../shuru_characters/land/leave_shuru.svg";
import l_landContentLotie from "../../../shuru_lottie/land/prayer_head_animation.json";
import l_landAngryLotie from "../../../shuru_lottie/land/angry_head_animation.json";
import l_landWorriedLotie from "../../../shuru_lottie/land/worried_head_animation.json";
import l_landAnxiousLotie from "../../../shuru_lottie/land/anxious_head_animation.json";
import l_landCryLotie from "../../../shuru_lottie/land/cry_head_animation.json";
import l_landExcitedLotie from "../../../shuru_lottie/land/excited_head_animation.json";
import l_landHappyLotie from "../../../shuru_lottie/land/happy_head_animation.json";
import l_landSleepLotie from "../../../shuru_lottie/land/sleep_head_animation.json";
import l_landSurpriseLotie from "../../../shuru_lottie/land/surprise_head_animation.json";
import l_shuruMain from "../../chatbotShuru/land/shuru_land_main.svg";

import o_grad_hard from "../feathers/Ocean/Ocean Medium Gradient.png";
import o_grad_light from "../feathers/Ocean/Ocean Light Gradient.png";
import o_grad_medium from "../feathers/Ocean/Ocean Medium Gradient.png";
import o_grad_feather from "../feathers/Ocean/Ocean Feather Gradient.png";

import o_personalize from "../heroNavButtons/ocean/personalize.svg";
import o_notify from "../heroNavButtons/ocean/notify.svg";

import o_titleIcon from "../shoorahTextBoxes/ocean/title.svg";
import o_descriptionIcon from "../shoorahTextBoxes/ocean/description.svg";
import o_addImageIcon from "../heroNavButtons/ocean/addImageIcon.svg";

import o_shoorahCircle from "../../affirmationBg/ocean.png";
import o_shuruContent from "../../../shuru_characters/ocean/shuru_ocean_content.svg";
import o_shuruAngry from "../../../shuru_characters/ocean/shuru_ocean_angry.svg";
import o_shuruAnxious from "../../../shuru_characters/ocean/shuru_ocean_anxious.svg";
import o_shuruExcited from "../../../shuru_characters/ocean/shuru_ocean_excited.svg";
import o_shuruHappy from "../../../shuru_characters/ocean/shuru_ocean_happy.svg";
import o_shuruSad from "../../../shuru_characters/ocean/shuru_ocean_sad.svg";
import o_shuruStressed from "../../chatbotShuru/ocean/shuru_ocean_fearful.svg";
import o_shuruSurprised from "../../../shuru_characters/ocean/shuru_ocean_suprised.svg";
import o_shuruTired from "../../../shuru_characters/ocean/shuru_ocean_tired.svg";
import o_shuruStrokeContent from "../../chatbotShuru/ocean/shuru_ocean_content.svg";
import o_shuruStrokeAngry from "../../chatbotShuru/ocean/shuru_ocean_angry.svg";
import o_shuruStrokeAnxious from "../../chatbotShuru/ocean/shuru_ocean_anxious.svg";
import o_shuruStrokeExcited from "../../chatbotShuru/ocean/shuru_ocean_excited.svg";
import o_shuruStrokeHappy from "../../chatbotShuru/ocean/shuru_ocean_happy.svg";
import o_shuruStrokeSad from "../../chatbotShuru/ocean/shuru_ocean_sad.svg";
import o_shuruStrokeSurprised from "../../chatbotShuru/ocean/shuru_ocean_suprised.svg";
import o_shuruStrokeTired from "../../chatbotShuru/ocean/shuru_ocean_tired.svg";

import o_shoorahContentLotie from "../../../shuru_lottie/ocean/prayer_head_animation.json";
import o_shoorahAngryLotie from "../../../shuru_lottie/ocean/angry_head_animation.json";
import o_shoorahWorriedLotie from "../../../shuru_lottie/ocean/worried_head_animation.json";
import o_shoorahAnxiousLotie from "../../../shuru_lottie/ocean/anxious_head_animation.json";
import o_shoorahCryLotie from "../../../shuru_lottie/ocean/cry_head_animation.json";
import o_shoorahExcitedLotie from "../../../shuru_lottie/ocean/excited_head_animation.json";
import o_shoorahHappyLotie from "../../../shuru_lottie/ocean/happy_head_animation.json";
import o_shoorahSleepLotie from "../../../shuru_lottie/ocean/sleep_head_animation.json";
import o_shoorahSurpriseLotie from "../../../shuru_lottie/ocean/surprise_head_animation.json";
import o_shuruMain from "../../chatbotShuru/ocean/shuru_ocean_main.svg";

import b_grad_hard from "../feathers/Bloom/bloom_heavy_gradient.svg";
import b_grad_light from "../feathers/Bloom/Bloom Light Gradient.png";
import b_grad_feather from "../feathers/Bloom/Bloom Feather Gradient.png";
import b_grad_medium from "../feathers/Bloom/Bloom Medium Gradient.png";

import b_personalize from "../heroNavButtons/bloom/personalize.svg";
import b_notify from "../heroNavButtons/bloom/notify.svg";
import b_homeActive from "../shoorahSidebarIcons/bloom/homeActive.svg";
import b_homeInactive from "../shoorahSidebarIcons/bloom/homeInactive.svg";
import b_journalActive from "../shoorahSidebarIcons/bloom/journalActive.svg";
import b_journalInactive from "../shoorahSidebarIcons/bloom/journalInactive.svg";
import b_ritualActive from "../shoorahSidebarIcons/bloom/ritualsActive.svg";
import b_ritualsInactive from "../shoorahSidebarIcons/bloom/ritualsInactive.svg";
import b_restoreActive from "../shoorahSidebarIcons/bloom/restoreActive.svg";
import b_restoreInactive from "../shoorahSidebarIcons/bloom/restoreInactive.svg";
import b_exploreActive from "../shoorahSidebarIcons/bloom/exploreActive.svg";
import b_exploreInactive from "../shoorahSidebarIcons/bloom/exploreInactive.svg";
import b_titleIcon from "../shoorahTextBoxes/bloom/title.svg";
import b_descriptionIcon from "../shoorahTextBoxes/bloom/description.svg";
import b_addImageIcon from "../heroNavButtons/bloom/addImageIcon.svg";
import b_shoorahCircle from "../../affirmationBg/bloom.png";
import b_shuruContent from "../../../shuru_characters/bloom/shuru_bloom_content.svg";
import b_shuruAngry from "../../../shuru_characters/bloom/shuru_bloom_angry.svg";
import b_shuruAnxious from "../../../shuru_characters/bloom/shuru_bloom_anxious.svg";
import b_shuruExcited from "../../../shuru_characters/bloom/shuru_bloom_excited.svg";
import b_shuruHappy from "../../../shuru_characters/bloom/shuru_bloom_happy.svg";
import b_shuruSad from "../../../shuru_characters/bloom/shuru_bloom_sad.svg";
import b_shuruStressed from "../../chatbotShuru/bloom/shuru_bloom_fearful.svg";
import b_shuruSurprised from "../../../shuru_characters/bloom/shuru_bloom_suprised.svg";
import b_shuruTired from "../../../shuru_characters/bloom/shuru_bloom_tired.svg";
import b_shuruStrokeContent from "../../chatbotShuru/bloom/shuru_bloom_content.svg";
import b_shuruStrokeAngry from "../../chatbotShuru/bloom/shuru_bloom_angry.svg";
import b_shuruStrokeAnxious from "../../chatbotShuru/bloom/shuru_bloom_anxious.svg";
import b_shuruStrokeExcited from "../../chatbotShuru/bloom/shuru_bloom_excited.svg";
import b_shuruStrokeHappy from "../../chatbotShuru/bloom/shuru_bloom_happy.svg";
import b_shuruStrokeSad from "../../chatbotShuru/bloom/shuru_bloom_sad.svg";
import b_shuruStrokeSurprised from "../../chatbotShuru/bloom/shuru_bloom_suprised.svg";
import b_shuruStrokeTired from "../../chatbotShuru/bloom/shuru_bloom_tired.svg";
import b_shuruProfile from "../../../shuru_characters/bloom/my_profile_shuru.svg";
import b_shuruLeave from "../../../shuru_characters/bloom/leave_shuru.svg";
import b_bloomContentLotie from "../../../shuru_lottie/bloom/prayer_head_animation.json";
import b_bloomAngryLotie from "../../../shuru_lottie/bloom/angry_head_animation.json";
import b_bloomWorriedLotie from "../../../shuru_lottie/bloom/worried_head_animation.json";
import b_bloomAnxiousLotie from "../../../shuru_lottie/bloom/anxious_head_animation.json";
import b_bloomCryLotie from "../../../shuru_lottie/bloom/cry_head_animation.json";
import b_bloomExcitedLotie from "../../../shuru_lottie/bloom/excited_head_animation.json";
import b_bloomHappyLotie from "../../../shuru_lottie/bloom/happy_head_animation.json";
import b_bloomSleepLotie from "../../../shuru_lottie/bloom/sleep_head_animation.json";
import b_bloomSurpriseLotie from "../../../shuru_lottie/bloom/surprise_head_animation.json";
import b_shuruMain from "../../chatbotShuru/bloom/shuru_bloom_main.svg";

import d_grad_hard from "../feathers/Desert/Desert Heavy Gradient.png";
import d_grad_light from "../feathers/Desert/Desert Light Gradient.png";
import d_grad_medium from "../feathers/Desert/Desert Medium Gradient.png";
import d_grad_feather from "../feathers/Desert/Desert Feather Gradient.png";

import d_personalize from "../heroNavButtons/desert/personalize.svg";
import d_notify from "../heroNavButtons/desert/notify.svg";

import d_titleIcon from "../shoorahTextBoxes/desert/title.svg";
import d_descriptionIcon from "../shoorahTextBoxes/desert/description.svg";
import d_addImageIcon from "../heroNavButtons/desert/addImageIcon.svg";

import d_shoorahCircle from "../../affirmationBg/desert.png";
import d_shuruContent from "../../../shuru_characters/desert/shuru_desert_content.svg";
import d_shuruAngry from "../../../shuru_characters/desert/shuru_desert_angry.svg";
import d_shuruAnxious from "../../../shuru_characters/desert/shuru_desert_anxious.svg";
import d_shuruExcited from "../../../shuru_characters/desert/shuru_desert_excited.svg";
import d_shuruHappy from "../../../shuru_characters/desert/shuru_desert_happy.svg";
import d_shuruSad from "../../../shuru_characters/desert/shuru_desert_sad.svg";
import d_shuruStressed from "../../chatbotShuru/desert/shuru_desert_fearful.svg";
import d_shuruSurprised from "../../../shuru_characters/desert/shuru_desert_suprised.svg";
import d_shuruTired from "../../../shuru_characters/desert/shuru_desert_tired.svg";
import d_shuruStrokeContent from "../../chatbotShuru/desert/shuru_desert_content.svg";
import d_shuruStrokeAngry from "../../chatbotShuru/desert/shuru_desert_angry.svg";
import d_shuruStrokeAnxious from "../../chatbotShuru/desert/shuru_desert_anxious.svg";
import d_shuruStrokeExcited from "../../chatbotShuru/desert/shuru_desert_excited.svg";
import d_shuruStrokeHappy from "../../chatbotShuru/desert/shuru_desert_happy.svg";
import d_shuruStrokeSad from "../../chatbotShuru/desert/shuru_desert_sad.svg";
import d_shuruStrokeSurprised from "../../chatbotShuru/desert/shuru_desert_suprised.svg";
import d_shuruStrokeTired from "../../chatbotShuru/desert/shuru_desert_tired.svg";

import d_shoorahContentLotie from "../../../shuru_lottie/desert/prayer_head_animation.json";
import d_shoorahAngryLotie from "../../../shuru_lottie/desert/angry_head_animation.json";
import d_shoorahWorriedLotie from "../../../shuru_lottie/desert/worried_head_animation.json";
import d_shoorahAnxiousLotie from "../../../shuru_lottie/desert/anxious_head_animation.json";
import d_shoorahCryLotie from "../../../shuru_lottie/desert/cry_head_animation.json";
import d_shoorahExcitedLotie from "../../../shuru_lottie/desert/excited_head_animation.json";
import d_shoorahHappyLotie from "../../../shuru_lottie/desert/happy_head_animation.json";
import d_shoorahSleepLotie from "../../../shuru_lottie/desert/sleep_head_animation.json";
import d_shoorahSurpriseLotie from "../../../shuru_lottie/desert/surprise_head_animation.json";
import d_shuruMain from "../../chatbotShuru/desert/shuru_desert_main.svg";

import sun_grad_hard from "../feathers/Sun/Sun Heavy Gradient.png";
import sun_grad_light from "../feathers/Sun/Sun Light Gradient.png";
import sun_grad_medium from "../feathers/Sun/Sun Medium Gradient.png";
import sun_grad_feather from "../feathers/Sun/Sun Feather Gradient.png";

import sun_personalize from "../heroNavButtons/sun/personalize.svg";
import sun_notify from "../heroNavButtons/sun/notify.svg";

import sun_addImageIcon from "../heroNavButtons/sun/addImageIcon.svg";

import sun_shoorahCircle from "../../affirmationBg/white.png";
import sun_shuruContent from "../../../shuru_characters/sun/shuru_sun_content.svg";
import sun_shuruAngry from "../../../shuru_characters/sun/shuru_sun_angry.svg";
import sun_shuruAnxious from "../../../shuru_characters/sun/shuru_sun_anxious.svg";
import sun_shuruExcited from "../../../shuru_characters/sun/shuru_sun_excited.svg";
import sun_shuruHappy from "../../../shuru_characters/sun/shuru_sun_happy.svg";
import sun_shuruSad from "../../../shuru_characters/sun/shuru_sun_sad.svg";
import sun_shuruStressed from "../../chatbotShuru/sun/shuru_sun_fearful.svg";
import sun_shuruSurprised from "../../../shuru_characters/sun/shuru_sun_suprised.svg";
import sun_shuruTired from "../../../shuru_characters/sun/shuru_sun_tired.svg";
import sun_shuruStrokeContent from "../../chatbotShuru/sun/shuru_sun_content.svg";
import sun_shuruStrokeAngry from "../../chatbotShuru/sun/shuru_sun_angry.svg";
import sun_shuruStrokeAnxious from "../../chatbotShuru/sun/shuru_sun_anxious.svg";
import sun_shuruStrokeExcited from "../../chatbotShuru/sun/shuru_sun_excited.svg";
import sun_shuruStrokeHappy from "../../chatbotShuru/sun/shuru_sun_happy.svg";
import sun_shuruStrokeSad from "../../chatbotShuru/sun/shuru_sun_sad.svg";
import sun_shuruStrokeSurprised from "../../chatbotShuru/sun/shuru_sun_suprised.svg";
import sun_shuruStrokeTired from "../../chatbotShuru/sun/shuru_sun_tired.svg";

import sun_shoorahContentLotie from "../../../shuru_lottie/sun/prayer_head_animation.json";
import sun_shoorahAngryLotie from "../../../shuru_lottie/sun/angry_head_animation.json";
import sun_shoorahWorriedLotie from "../../../shuru_lottie/sun/worried_head_animation.json";
import sun_shoorahAnxiousLotie from "../../../shuru_lottie/sun/anxious_head_nimation.json";
import sun_shoorahCryLotie from "../../../shuru_lottie/sun/cry_head_animation.json";
import sun_shoorahExcitedLotie from "../../../shuru_lottie/sun/excited_head_animation.json";
import sun_shoorahHappyLotie from "../../../shuru_lottie/sun/happy_head_animation.json";
import sun_shoorahSleepLotie from "../../../shuru_lottie/sun/sleep_head_animation.json";
import sun_shoorahSurpriseLotie from "../../../shuru_lottie/sun/surprise_head_animation.json";
import sun_shuruMain from "../../chatbotShuru/sun/shuru_sun_main.svg";

import shoorah_badge_grad from "../../../images/shoorahBadgeGrad.svg";
import land_badge_grad from "../../../images/landBadgeGrad.svg";
import bloom_badge_grad from "../../../images/bloombadgeGrad.svg";
import ocean_badge_grad from "../../../images/oceanBadgeGrad.svg";
import dessert_badge_grad from "../../../images/desertBadgeGrad.svg";
import sun_badge_grad from "../../../images/sunBadgeGrad.svg";

export const shoorah = {
  primary: "#4A56DA",
  focusBackgroundGradient:
    "background: linear-gradient(180deg, #DFDBFF 0%, #E7EAFF 33.33%, #D3DAFF 66.67%, #D3DAFF 100%)",
  toggleBackground: "#E4E9F8",
  searchIconPlaceholderText: "#313B6B",
  sideNavtextActive: "#18202F",
  sideNavtextInactive: "#313B6B",

  shapeColor: `#e5eaf9`,

  shoorah1: "#e5eaf9",
  shoorah2: "#B6C0F3",
  shoorah3: "#888beb",
  shoorah4: "#6167e8",
  shoorah5: "#4a56db",
  shoorah6: "#3a47ab",
  shoorah7: "#313b6b",
  shoorah8: "#18202f",

  gradient_heavy: s_grad_hard,
  gradient_light: s_grad_light,
  gradient_feather: s_grad_feather,
  gradient_medium: s_grad_medium,

  personalize: personalize,
  notify: notify,
  badge_grad: shoorah_badge_grad,

  home_active: homeActive,
  home_in_active: homeInactive,
  journal_active: journalActive,
  journal_in_active: journalInactive,
  rituals_active: ritualActive,
  rituals_in_active: ritualsInactive,
  restore_active: restoreActive,
  restore_in_active: restoreInactive,
  explore_active: exploreActive,
  explore_in_active: exploreInactive,

  box_shape: boxShape,
  circle_shape: circleShape,
  star_shape: starShape,
  edge_box: edgeBox,

  add_image_icon: addImageIcon,
  title_icon: titleIcon,
  description_icon: descriptionIcon,

  shoorah_circle: shoorahCircle,
  shuruContent: shuruContent,
  shuruAngry: shuruAngry,
  shuruAnxious: shuruAnxious,
  shuruExcited: shuruExcited,
  shuruHappy: shuruHappy,
  shuruStressed: shuruStressed,
  shuruTired: shuruTired,
  shuruSad: shuruSad,
  shuruSurprised: shuruSurprised,
  shuruStrokeContent: shuruStrokeContent,
  shuruStrokeAngry: shuruStrokeAngry,
  shuruStrokeAnxious: shuruStrokeAnxious,
  shuruStrokeExcited: shuruStrokeExcited,
  shuruStrokeHappy: shuruStrokeHappy,
  shuruStrokeTired: shuruStrokeTired,
  shuruStrokeSad: shuruStrokeSad,
  shuruStrokeSurprised: shuruStrokeSurprised,
  shuruMain: shuruMain,

  shuruContentLotie: shoorahContentLotie,
  shuruAngryLotie: shoorahAngryLotie,
  shuruAnxiousLotie: shoorahAnxiousLotie,
  shuruSurpriseLotie: shoorahSurpriseLotie,
  shuruWorriedLotie: shoorahWorriedLotie,
  shuruCryLotie: shoorahCryLotie,
  shuruHappyLotie: shoorahHappyLotie,
  shuruSleepLotie: shoorahSleepLotie,
  shuruExcitedLotie: shoorahExcitedLotie,

  shuruProfile: shuruProfile,
  shuruLeave: shuruLeave,
};

export const land = {
  primary: "#67A04A",
  focusBackgroundGradient:
    "background: linear-gradient(274.58deg, #D9EACA 5.72%, #E2F4D2 35.76%, #CEE7BA 65.8%, #F1FDE9 95.84%)",
  toggleBackground: "#F1FAE3",
  searchIconPlaceholderText: "#2E6630",
  sideNavtextActive: "#112911",
  sideNavtextInactive: "#2E6630",
  badge_grad: land_badge_grad,

  land1: "#f1fae3",
  land2: "#ddf2bf",
  land3: "#c2ec97",
  land4: "#8fd460",
  land5: "#67a14a",
  land6: "#438038",
  land7: "#2e6630",
  land8: "#112911",

  shapeColor: `#ddf2bf`,

  gradient_heavy: l_grad_hard,
  gradient_light: l_grad_light,
  gradient_feather: l_grad_feather,
  gradient_medium: l_grad_medium,

  personalize: l_personalize,
  notify: l_notify,

  home_active: l_homeActive,
  home_in_active: l_homeInactive,
  journal_active: l_journalActive,
  journal_in_active: l_journalInactive,
  rituals_active: l_ritualActive,
  rituals_in_active: l_ritualsInactive,
  restore_active: l_restoreActive,
  restore_in_active: l_restoreInactive,
  explore_active: l_exploreActive,
  explore_in_active: l_exploreInactive,

  box_shape: boxShape,
  circle_shape: circleShape,
  star_shape: starShape,
  edge_box: edgeBox,

  add_image_icon: l_addImageIcon,
  title_icon: l_titleIcon,
  description_icon: l_descriptionIcon,

  shoorah_circle: l_shoorahCircle,
  shuruContent: l_shuruContent,
  shuruAngry: l_shuruAngry,
  shuruAnxious: l_shuruAnxious,
  shuruExcited: l_shuruExcited,
  shuruHappy: l_shuruHappy,
  shuruStressed: l_shuruStressed,
  shuruTired: l_shuruTired,
  shuruSad: l_shuruSad,
  shuruSurprised: l_shuruSurprised,
  shuruStrokeContent: l_shuruStrokeContent,
  shuruStrokeAngry: l_shuruStrokeAngry,
  shuruStrokeAnxious: l_shuruStrokeAnxious,
  shuruStrokeExcited: l_shuruStrokeExcited,
  shuruStrokeHappy: l_shuruStrokeHappy,
  shuruStrokeTired: l_shuruStrokeTired,
  shuruStrokeSad: l_shuruStrokeSad,
  shuruStrokeSurprised: l_shuruStrokeSurprised,
  shuruMain: l_shuruMain,

  shuruContentLotie: l_landContentLotie,
  shuruAngryLotie: l_landAngryLotie,
  shuruAnxiousLotie: l_landAnxiousLotie,
  shuruSurpriseLotie: l_landSurpriseLotie,
  shuruWorriedLotie: l_landWorriedLotie,
  shuruCryLotie: l_landCryLotie,
  shuruHappyLotie: l_landHappyLotie,
  shuruSleepLotie: l_landSleepLotie,
  shuruExcitedLotie: l_landExcitedLotie,

  shuruProfile: l_shuruProfile,
  shuruLeave: l_shuruLeave,
};

export const bloom = {
  primary: "#f05289",
  focusBackgroundGradient:
    "background: linear-gradient(264.95deg, #FEE3EE 5.16%, #FED4E6 34.73%, #FED4E5 64.31%, #FFADC1 93.88%)",
  toggleBackground: "#F1FAE3",
  sideNavtextActive: "#112911",
  searchIconPlaceholderText: "#2E6630",
  sideNavtextInactive: "#2E6630",
  badge_grad: bloom_badge_grad,

  bloom1: "#f9f2f5",
  bloom2: "#ffc6e4",
  bloom3: "#ff9bcb",
  bloom4: "#f076a0",
  bloom5: "#f05289",
  bloom6: "#bf3261",
  bloom7: "#731c3f",
  bloom8: "#381e23",
  shuruMain: b_shuruMain,

  shapeColor: `#ffc6e4`,

  gradient_heavy: b_grad_hard,
  gradient_light: b_grad_light,
  gradient_feather: b_grad_feather,
  gradient_medium: b_grad_medium,

  personalize: b_personalize,
  notify: b_notify,

  home_active: b_homeActive,
  home_in_active: b_homeInactive,
  journal_active: b_journalActive,
  journal_in_active: b_journalInactive,
  rituals_active: b_ritualActive,
  rituals_in_active: b_ritualsInactive,
  restore_active: b_restoreActive,
  restore_in_active: b_restoreInactive,
  explore_active: b_exploreActive,
  explore_in_active: b_exploreInactive,

  box_shape: boxShape,
  circle_shape: circleShape,
  star_shape: starShape,
  edge_box: edgeBox,

  add_image_icon: b_addImageIcon,
  title_icon: b_titleIcon,
  description_icon: b_descriptionIcon,

  shoorah_circle: b_shoorahCircle,

  shuruContent: b_shuruContent,
  shuruAngry: b_shuruAngry,
  shuruAnxious: b_shuruAnxious,
  shuruExcited: b_shuruExcited,
  shuruHappy: b_shuruHappy,
  shuruStressed: b_shuruStressed,
  shuruTired: b_shuruTired,
  shuruSad: b_shuruSad,
  shuruSurprised: b_shuruSurprised,
  shuruStrokeContent: b_shuruStrokeContent,
  shuruStrokeAngry: b_shuruStrokeAngry,
  shuruStrokeAnxious: b_shuruStrokeAnxious,
  shuruStrokeExcited: b_shuruStrokeExcited,
  shuruStrokeHappy: b_shuruStrokeHappy,
  shuruStrokeTired: b_shuruStrokeTired,
  shuruStrokeSad: b_shuruStrokeSad,
  shuruStrokeSurprised: b_shuruStrokeSurprised,

  shuruContentLotie: b_bloomContentLotie,
  shuruAngryLotie: b_bloomAngryLotie,
  shuruAnxiousLotie: b_bloomAnxiousLotie,
  shuruSurpriseLotie: b_bloomSurpriseLotie,
  shuruWorriedLotie: b_bloomWorriedLotie,
  shuruCryLotie: b_bloomCryLotie,
  shuruHappyLotie: b_bloomHappyLotie,
  shuruSleepLotie: b_bloomSleepLotie,
  shuruExcitedLotie: b_bloomExcitedLotie,

  shuruProfile: b_shuruProfile,
  shuruLeave: b_shuruLeave,
};

export const desert = {
  primary: "#ecc09e",
  focusBackgroundGradient:
    "background: linear-gradient(180deg, #DFDBFF 0%, #E7EAFF 33.33%, #D3DAFF 66.67%, #D3DAFF 100%)",
  toggleBackground: "#E4E9F8",
  searchIconPlaceholderText: "#703115",
  sideNavtextActive: "#30160a",
  sideNavtextInactive: "#703115",
  badge_grad: dessert_badge_grad,

  shapeColor: `#f6e6cf`,
  shuruMain: d_shuruMain,

  shoorah_1: "#f7f5f0",
  shoorah_2: "#f6e6cf",
  shoorah_3: "#ecc09e",
  shoorah_4: "#e2a280",
  shoorah_5: "#d97f56",
  shoorah_6: "#ca5926",
  shoorah_7: "#703115",
  shoorah_8: "#30160a",

  gradient_heavy: d_grad_hard,
  gradient_light: d_grad_light,
  gradient_feather: d_grad_feather,
  gradient_medium: d_grad_medium,

  personalize: d_personalize,
  notify: d_notify,

  box_shape: boxShape,
  circle_shape: circleShape,
  star_shape: starShape,
  edge_box: edgeBox,

  add_image_icon: d_addImageIcon,
  title_icon: d_titleIcon,
  description_icon: d_descriptionIcon,

  shoorah_circle: d_shoorahCircle,
  shuruContent: d_shuruContent,
  shuruAngry: d_shuruAngry,
  shuruAnxious: d_shuruAnxious,
  shuruExcited: d_shuruExcited,
  shuruHappy: d_shuruHappy,
  shuruStressed: d_shuruStressed,
  shuruTired: d_shuruTired,
  shuruSad: d_shuruSad,
  shuruSurprised: d_shuruSurprised,
  shuruStrokeContent: d_shuruStrokeContent,
  shuruStrokeAngry: d_shuruStrokeAngry,
  shuruStrokeAnxious: d_shuruStrokeAnxious,
  shuruStrokeExcited: d_shuruStrokeExcited,
  shuruStrokeHappy: d_shuruStrokeHappy,
  shuruStrokeTired: d_shuruStrokeTired,
  shuruStrokeSad: d_shuruStrokeSad,
  shuruStrokeSurprised: d_shuruStrokeSurprised,

  shuruContentLotie: d_shoorahContentLotie,
  shuruAngryLotie: d_shoorahAngryLotie,
  shuruAnxiousLotie: d_shoorahAnxiousLotie,
  shuruSurpriseLotie: d_shoorahSurpriseLotie,
  shuruWorriedLotie: d_shoorahWorriedLotie,
  shuruCryLotie: d_shoorahCryLotie,
  shuruHappyLotie: d_shoorahHappyLotie,
  shuruSleepLotie: d_shoorahSleepLotie,
  shuruExcitedLotie: d_shoorahExcitedLotie,
};

export const ocean = {
  primary: "#7dc4a6",
  focusBackgroundGradient:
    "background: linear-gradient(180deg, #DFDBFF 0%, #E7EAFF 33.33%, #D3DAFF 66.67%, #D3DAFF 100%)",
  toggleBackground: "#E4E9F8",
  searchIconPlaceholderText: "#142929",
  sideNavtextActive: "#144f52",
  sideNavtextInactive: "#142929",
  shuruMain: o_shuruMain,
  badge_grad: ocean_badge_grad,

  shapeColor: `#e8fff3`,

  shoorah1: "#e8fff3",
  shoorah2: "#acecd0",
  shoorah3: "#6be0bd",
  shoorah4: "#7dc4a6",
  shoorah5: "#21bdad",
  shoorah6: "#26918f",
  shoorah7: "#144f52",
  shoorah8: "#142929",

  gradient_heavy: o_grad_hard,
  gradient_light: o_grad_light,
  gradient_feather: o_grad_feather,
  gradient_medium: o_grad_medium,

  personalize: o_personalize,
  notify: o_notify,

  box_shape: boxShape,
  circle_shape: circleShape,
  star_shape: starShape,
  edge_box: edgeBox,

  add_image_icon: o_addImageIcon,
  title_icon: o_titleIcon,
  description_icon: o_descriptionIcon,

  shoorah_circle: o_shoorahCircle,
  shuruContent: o_shuruContent,
  shuruAngry: o_shuruAngry,
  shuruAnxious: o_shuruAnxious,
  shuruExcited: o_shuruExcited,
  shuruHappy: o_shuruHappy,
  shuruStressed: o_shuruStressed,
  shuruTired: o_shuruTired,
  shuruSad: o_shuruSad,
  shuruSurprised: o_shuruSurprised,
  shuruStrokeContent: o_shuruStrokeContent,
  shuruStrokeAngry: o_shuruStrokeAngry,
  shuruStrokeAnxious: o_shuruStrokeAnxious,
  shuruStrokeExcited: o_shuruStrokeExcited,
  shuruStrokeHappy: o_shuruStrokeHappy,
  shuruStrokeTired: o_shuruStrokeTired,
  shuruStrokeSad: o_shuruStrokeSad,
  shuruStrokeSurprised: o_shuruStrokeSurprised,

  shuruContentLotie: o_shoorahContentLotie,
  shuruAngryLotie: o_shoorahAngryLotie,
  shuruAnxiousLotie: o_shoorahAnxiousLotie,
  shuruSurpriseLotie: o_shoorahSurpriseLotie,
  shuruWorriedLotie: o_shoorahWorriedLotie,
  shuruCryLotie: o_shoorahCryLotie,
  shuruHappyLotie: o_shoorahHappyLotie,
  shuruSleepLotie: o_shoorahSleepLotie,
  shuruExcitedLotie: o_shoorahExcitedLotie,
};

export const sun = {
  primary: "#ffdb42",

  focusBackgroundGradient:
    "background: linear-gradient(180deg, #DFDBFF 0%, #E7EAFF 33.33%, #D3DAFF 66.67%, #D3DAFF 100%)",
  toggleBackground: "#E4E9F8",
  searchIconPlaceholderText: "#142929",
  sideNavtextActive: "#144f52",
  sideNavtextInactive: "#142929",
  badge_grad: sun_badge_grad,

  shuruMain: sun_shuruMain,

  shapeColor: `#fff2b0`,

  shoorah1: "#ffffd6",
  shoorah2: "#fff2b0",
  shoorah3: "#ffe873",
  shoorah4: "#ffdb42",
  shoorah5: "#edcc12",
  shoorah6: "#d9b230",
  shoorah7: "#967836",
  shoorah8: "#362e1c",

  gradient_heavy: sun_grad_hard,
  gradient_light: sun_grad_light,
  gradient_feather: sun_grad_feather,
  gradient_medium: sun_grad_medium,

  personalize: sun_personalize,
  notify: sun_notify,

  box_shape: boxShape,
  circle_shape: circleShape,
  star_shape: starShape,
  edge_box: edgeBox,

  add_image_icon: sun_addImageIcon,

  shoorah_circle: sun_shoorahCircle,
  shuruContent: sun_shuruContent,
  shuruAngry: sun_shuruAngry,
  shuruAnxious: sun_shuruAnxious,
  shuruExcited: sun_shuruExcited,
  shuruHappy: sun_shuruHappy,
  shuruStressed: sun_shuruStressed,
  shuruTired: sun_shuruTired,
  shuruSad: sun_shuruSad,
  shuruSurprised: sun_shuruSurprised,
  shuruStrokeContent: sun_shuruStrokeContent,
  shuruStrokeAngry: sun_shuruStrokeAngry,
  shuruStrokeAnxious: sun_shuruStrokeAnxious,
  shuruStrokeExcited: sun_shuruStrokeExcited,
  shuruStrokeHappy: sun_shuruStrokeHappy,
  shuruStrokeTired: sun_shuruStrokeTired,
  shuruStrokeSad: sun_shuruStrokeSad,
  shuruStrokeSurprised: sun_shuruStrokeSurprised,

  shuruContentLotie: sun_shoorahContentLotie,
  shuruAngryLotie: sun_shoorahAngryLotie,
  shuruAnxiousLotie: sun_shoorahAnxiousLotie,
  shuruSurpriseLotie: sun_shoorahSurpriseLotie,
  shuruWorriedLotie: sun_shoorahWorriedLotie,
  shuruCryLotie: sun_shoorahCryLotie,
  shuruHappyLotie: sun_shoorahHappyLotie,
  shuruSleepLotie: sun_shoorahSleepLotie,
  shuruExcitedLotie: sun_shoorahExcitedLotie,
};
