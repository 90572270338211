import { SidebarContext } from "../../context/SidebarContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "../../contents/context/themeContext";

const Loader = () => {
  const { isShow } = useContext(SidebarContext);
  const location = useLocation();
  const { theme } = useTheme();

  return (
    <div
      className={`${
        isShow &&
        !["/", "/reset-password", "/forgot-password"]?.includes(
          location?.pathname,
        )
          ? "left-[246px]"
          : "left-0"
      } fixed top-0 right-0 bottom-0 z-30 flex items-center justify-center bg-gray-100 bg-opacity-90 p-5`}
    >
      <div className="flex animate-pulse space-x-2">
        <div className={`h-3 w-3 ${theme.shoorah_bg_5} rounded-full`}></div>
        <div className={`h-3 w-3 ${theme.shoorah_bg_4} rounded-full`}></div>
        <div className={`h-3 w-3 ${theme.shoorah_bg_5} rounded-full`}></div>
      </div>
    </div>
  );
};

export default Loader;
