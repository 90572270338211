export const DEVICE_TYPE = 3;

export const EMERGENCY_NUMBER = "8006895652";

export const USER_TYPE = [
  { name: "Select type", value: "" },
  { name: "Super Admin", value: 0 },
  { name: "Sub Admin", value: 1 },
];

export const MARITAL_STATUS = [
  { name: "Married", value: "0" },
  { name: "Un-Married", value: "1" },
];

export const JOB_ROLES = [
  { name: "Account Executive", value: "account executive" },
  { name: "Account Manager", value: "account manager" },
  { name: "Accountant", value: "accountant" },
  { name: "Additive Manufacturing", value: "additive manufacturing" },
  { name: "Administrative Assistant", value: "administrative assistant" },
  { name: "Advertising and PR", value: "advertising and pr" },
  { name: "Advertising", value: "advertising" },
  { name: "Aerospace", value: "aerospace" },
  { name: "AI Ethics Researcher", value: "ai ethics researcher" },
  { name: "Automotive", value: "automotive" },
  { name: "Air Traffic Controller", value: "air traffic controller" },
  { name: "Architect", value: "architect" },
  { name: "Agriculture and Food", value: "agriculture and food" },
  { name: "Architecture and Design", value: "architecture and design" },
  { name: "Agriculture and Farming", value: "agriculture and farming" },
  { name: "Accounting and Taxation", value: "accounting and taxation" },
  { name: "Actuarial Science", value: "actuarial science" },
  { name: "Agrifood", value: "agrifood" },
  { name: "Agrochemicals", value: "agrochemicals" },
  { name: "Air Transport", value: "air transport" },
  { name: "App Development", value: "app development" },
  { name: "Art and Antiques", value: "art and antiques" },
  { name: "Asset Management", value: "asset management" },
  { name: "Auction and Sales", value: "auction and sales" },
  { name: "Automotive Aftermarket", value: "automotive aftermarket" },
  { name: "Antiques and Collectibles", value: "antiques and collectibles" },
  { name: "Art and Design", value: "art and design" },
  {
    name: "Automotive Parts and Accessories",
    value: "automotive parts and accessories",
  },
  { name: "Agricultural", value: "agricultural" },
  { name: "Apparel and Textiles", value: "apparel and textiles" },
  { name: "Automation and Robotics", value: "automation and robotics" },
  { name: "Aviation", value: "aviation" },
  { name: "Biomedical Engineer", value: "biomedical engineer" },
  { name: "Biotech Engineer", value: "biotech engineer" },
  { name: "Biotechnologist", value: "biotechnologist" },
  { name: "Beauty and Wellness", value: "beauty and wellness" },
  { name: "Blockchain Developer", value: "blockchain developer" },
  { name: "Business Analyst", value: "business analyst" },
  { name: "Business Owner", value: "business owner" },
  { name: "Bakery and Confectionery", value: "bakery and confectionery" },
  { name: "Beauty and Personal Care", value: "beauty and personal care" },
  { name: "Book Publishing", value: "book publishing" },
  { name: "Business Services", value: "business services" },
  { name: "Banking", value: "banking" },
  { name: "Biotechnology", value: "biotechnology" },
  { name: "Beauty Services", value: "beauty services" },
  { name: "Betting and Gambling", value: "betting and gambling" },
  { name: "Boat Building", value: "boat building" },
  { name: "Brewing", value: "brewing" },
  { name: "Building Materials", value: "building materials" },
  { name: "Business Support Services", value: "business support services" },
  {
    name: "Banking and Financial Services",
    value: "banking and financial services",
  },
  { name: "Beauty and Personal Care", value: "beauty and personal care" },
  { name: "Biotechnology", value: "biotechnology" },
  { name: "CEO", value: "ceo" },
  { name: "Chef", value: "chef" },
  { name: "Consulting", value: "consulting" },
  {
    name: "Creative Arts and Entertainment",
    value: "creative arts and entertainment",
  },
  { name: "Chemical Engineer", value: "chemical engineer" },
  { name: "Chemist", value: "chemist" },
  { name: "Copywriter", value: "copywriter" },
  {
    name: "Customer Service Representative",
    value: "customer service representative",
  },
  { name: "Clean Energy", value: "clean energy" },
  { name: "Capital Markets", value: "capital markets" },
  { name: "Caregiving", value: "caregiving" },
  { name: "Cash Handling", value: "cash handling" },
  { name: "Ceramics and Tiles", value: "ceramics and tiles" },
  { name: "Charitable Giving", value: "charitable giving" },
  { name: "Children's Services", value: "children's services" },
  { name: "Cleaning Services", value: "cleaning services" },
  { name: "Clinical Research", value: "clinical research" },
  { name: "Commercial Printing", value: "commercial printing" },
  { name: "Community Services", value: "community services" },
  { name: "Computers and Peripherals", value: "computers and peripherals" },
  { name: "Construction Materials", value: "construction materials" },
  { name: "Corporate Advisory", value: "corporate advisory" },
  { name: "Courier Services", value: "courier services" },
  { name: "Creative Services", value: "creative services" },
  { name: "Chemical", value: "chemical" },
  { name: "Construction", value: "construction" },
  { name: "Ceramics and Glass", value: "ceramics and glass" },
  { name: "Charitable Organizations", value: "charitable organizations" },
  { name: "Childcare and Education", value: "childcare and education" },
  {
    name: "Cleaning and Janitorial Services",
    value: "cleaning and janitorial services",
  },
  { name: "Coal and Mining", value: "coal and mining" },
  { name: "Commercial Real Estate", value: "commercial real estate" },
  { name: "Computer Hardware", value: "computer hardware" },
  { name: "Computer Networking", value: "computer networking" },
  {
    name: "Construction and Engineering",
    value: "construction and engineering",
  },
  { name: "Contract Manufacturing", value: "contract manufacturing" },
  { name: "Corporate Training", value: "corporate training" },
  { name: "Chief Data Officer", value: "chief data officer" },
  { name: "Climate Change Analyst", value: "climate change analyst" },
  { name: "Content Creator", value: "content creator" },
  { name: "Cryptocurrency Analyst", value: "cryptocurrency analyst" },
  { name: "Cybersecurity Specialist", value: "cybersecurity specialist" },
  { name: "Courier and Postal Services", value: "courier and postal services" },
  { name: "Consumer Electronics", value: "consumer electronics" },
  { name: "Cosmetics", value: "cosmetics" },
  {
    name: "Digital Marketing Strategist",
    value: "digital marketing strategist",
  },
  { name: "Drone Operator", value: "drone operator" },
  { name: "Data Analytics", value: "data analytics" },
  { name: "Data Scientist", value: "data scientist" },
  { name: "Dairy and Frozen Food", value: "dairy and frozen food" },
  { name: "Data and Analytics", value: "data and analytics" },
  { name: "Defense and Space", value: "defense and space" },
  { name: "Dental Assistant", value: "dental assistant" },
  { name: "Dental Hygienist", value: "dental hygienist" },
  { name: "Dentist", value: "dentist" },
  { name: "Defense Industry", value: "defense industry" },
  { name: "Demolition and Dismantling", value: "demolition and dismantling" },
  { name: "Direct Selling", value: "direct selling" },
  { name: "Dispute Resolution", value: "dispute resolution" },
  { name: "Document Management", value: "document management" },
  { name: "Durable Medical Equipment", value: "durable medical equipment" },
  { name: "Director", value: "director" },
  { name: "Digital Marketing", value: "digital marketing" },
  { name: "Defense", value: "defense" },
  { name: "Electrical Engineer", value: "electrical engineer" },
  { name: "Emergency Medical Technician (EMT)", value: "EMT" },
  { name: "Esports", value: "esports" },
  { name: "E-commerce", value: "e-commerce" },
  { name: "Education Technology", value: "education technology" },
  { name: "Electrical Equipment", value: "electrical equipment" },
  { name: "Electrical Services", value: "electrical services" },
  { name: "Electronics", value: "electronics" },
  { name: "Environmental Consulting", value: "environmental consulting" },
  { name: "Event Management", value: "event management" },
  {
    name: "Electrical and Electronic Manufacturing",
    value: "electrical and electronic manufacturing",
  },
  { name: "Electronic Components", value: "electronic components" },
  {
    name: "Electronic Manufacturing Services",
    value: "electronic manufacturing services",
  },
  { name: "Employment Services", value: "employment services" },
  { name: "Energy Efficiency Services", value: "energy efficiency services" },
  { name: "Engineering Consultancy", value: "engineering consultancy" },
  { name: "Environmental Cleanup", value: "environmental cleanup" },
  { name: "Education", value: "education" },
  { name: "Energy", value: "energy" },
  { name: "Engineering", value: "engineering" },
  { name: "Environmental Services", value: "environmental services" },
  { name: "Ergonomics", value: "ergonomics" },
  { name: "Escrow Services", value: "escrow services" },
  { name: "Events Management", value: "events management" },
  { name: "Environmental Scientist", value: "environmental scientist" },
  { name: "Event Planner", value: "event planner" },
  { name: "Energy and Utilities", value: "energy and utilities" },
  { name: "E-commerce Manager", value: "e-commerce manager" },
  { name: "Epidemiologist", value: "epidemiologist" },
  { name: "Education", value: "education" },
  { name: "Environmental Services", value: "environmental services" },
  { name: "Fashion and Apparel", value: "fashion and apparel" },
  { name: "Food and Beverage", value: "food and beverage" },
  { name: "Financial Services", value: "financial services" },
  { name: "Financial Analyst", value: "financial analyst" },
  { name: "Financial Consultant", value: "financial consultant" },
  { name: "Financial Planner", value: "financial planner" },
  { name: "Facilities Management", value: "facilities management" },
  { name: "Fashion Accessories", value: "fashion accessories" },
  { name: "Financial Planning", value: "financial planning" },
  { name: "Fashion Stylist", value: "fashion stylist" },
  { name: "Fitness Trainer", value: "fitness trainer" },
  { name: "Fishery and Aquaculture", value: "fishery and aquaculture" },
  { name: "Food and Grocery", value: "food and grocery" },
  { name: "Food Delivery", value: "food delivery" },
  { name: "Freight and Logistics", value: "freight and logistics" },
  { name: "Furniture and Fixtures", value: "furniture and fixtures" },
  { name: "Fashion", value: "fashion" },
  { name: "Financial Services", value: "financial services" },
  { name: "Food and Beverage", value: "food and beverage" },
  { name: "Forensic Scientist", value: "forensic scientist" },
  { name: "Geologist", value: "geologist" },
  { name: "GIS Analyst", value: "GIS analyst" },
  { name: "Graphic Designer", value: "graphic designer" },
  { name: "Graphic Illustrator", value: "graphic illustrator" },
  { name: "Graphic Design", value: "graphic design" },
  { name: "Gaming and Gambling", value: "gaming and gambling" },
  { name: "Gems and Jewelry", value: "gems and jewelry" },
  { name: "Glass and Ceramics", value: "glass and ceramics" },
  { name: "Genetic Counselor", value: "genetic counselor" },
  { name: "Green Energy Consultant", value: "green energy consultant" },
  { name: "Graphic Design", value: "graphic design" },
  { name: "Home Improvement", value: "home improvement" },
  { name: "Hotel Manager", value: "hotel manager" },
  { name: "Healthcare", value: "healthcare" },
  { name: "Human Resource Coordinator", value: "human resource coordinator" },
  { name: "Human Resources Manager", value: "human resources manager" },
  { name: "Healthcare Technology", value: "healthcare technology" },
  { name: "Heavy Industry", value: "heavy industry" },
  { name: "Home Furnishings", value: "home furnishings" },
  { name: "Human-Centered Designer", value: "human-centered designer" },
  {
    name: "Information Security Analyst",
    value: "information security analyst",
  },
  { name: "Information Technology", value: "information technology" },
  { name: "Insurance", value: "insurance" },
  { name: "Industrial Automation", value: "industrial automation" },
  { name: "Industrial Engineering", value: "industrial engineering" },
  { name: "Industrial Machinery", value: "industrial machinery" },
  {
    name: "Influencer Marketing Manager",
    value: "influencer marketing manager",
  },
  {
    name: "Information Technology Consulting",
    value: "information technology consulting",
  },
  { name: "Insurance Broking", value: "insurance broking" },
  { name: "Internet of Things", value: "internet of things" },
  { name: "IT Consulting and Services", value: "it consulting and services" },
  { name: "Insurance Agent", value: "insurance agent" },
  { name: "IT Consultant", value: "IT consultant" },
  { name: "Insurance", value: "insurance" },
  { name: "Information Technology", value: "information technology" },
  { name: "Java Developer", value: "java developer" },
  { name: "Java Programmer", value: "Java programmer" },
  { name: "Jewelry and Watches", value: "jewelry and watches" },
  { name: "Job Search and Recruitment", value: "job search and recruitment" },
  { name: "Jewelry Designer", value: "jewelry designer" },
  { name: "Journalist", value: "journalist" },
  { name: "K-12 Teacher", value: "K-12 teacher" },
  { name: "Key Account Executive", value: "key account executive" },
  { name: "Kinesiologist", value: "kinesiologist" },
  { name: "Kitchen Manager", value: "kitchen manager" },
  { name: "Laboratory Technician", value: "laboratory technician" },
  { name: "Legal Services", value: "legal services" },
  { name: "Logistics", value: "logistics" },
  { name: "Life Sciences", value: "life sciences" },
  { name: "Lifestyle and Recreation", value: "lifestyle and recreation" },
  { name: "Landscape Architect", value: "landscape architect" },
  { name: "Lawyer", value: "lawyer" },
  { name: "Legal Assistant", value: "legal assistant" },
  { name: "Market Research Analyst", value: "market research analyst" },
  { name: "Marketing Coordinator", value: "marketing coordinator" },
  { name: "Materials Engineer", value: "materials engineer" },
  { name: "Manufacturing", value: "manufacturing" },
  {
    name: "Machine Translation Engineer",
    value: "machine translation engineer",
  },
  { name: "Medical Illustrator", value: "medical illustrator" },
  { name: "Mobile App Tester", value: "mobile app tester" },
  { name: "Management Consulting", value: "management consulting" },
  { name: "Marketing Consulting", value: "marketing consulting" },
  { name: "Medical Devices", value: "medical devices" },
  { name: "Medical Diagnostics", value: "medical diagnostics" },
  { name: "Medical Tourism", value: "medical tourism" },
  { name: "Media and Entertainment", value: "media and entertainment" },
  { name: "Mining", value: "mining" },
  { name: "Meteorologist", value: "meteorologist" },
  { name: "Network Administrator", value: "network administrator" },
  { name: "Neurologist", value: "neurologist" },
  { name: "Nurse", value: "nurse" },
  { name: "Nanoengineering Specialist", value: "nanoengineering specialist" },
  { name: "Nanotechnology", value: "nanotechnology" },
  { name: "Nutritionist", value: "nutritionist" },
  {
    name: "Occupational Safety Specialist",
    value: "occupational safety specialist",
  },
  { name: "Occupational Therapist", value: "occupational therapist" },
  { name: "Oil and Gas", value: "oil and gas" },
  { name: "Office Manager", value: "office manager" },
  { name: "Oceanographer", value: "oceanographer" },
  { name: "Organizational Psychologist", value: "organizational psychologist" },
  { name: "Operations Manager", value: "operations manager" },
  { name: "Pharmacist", value: "pharmacist" },
  { name: "Physiotherapist", value: "physiotherapist" },
  { name: "Photographer", value: "photographer" },
  { name: "Patient Advocate", value: "patient advocate" },
  { name: "Podcast Producer", value: "podcast producer" },
  { name: "Pharmaceuticals", value: "pharmaceuticals" },
  { name: "Professional Services", value: "professional services" },
  { name: "Public Administration", value: "public administration" },
  { name: "Product Manager", value: "product manager" },
  { name: "Quantitative Analyst", value: "quantitative analyst" },
  { name: "Quantum Physicist", value: "quantum physicist" },
  { name: "Radiologist", value: "radiologist" },
  { name: "Research Scientist", value: "research scientist" },
  { name: "Robotics Engineer", value: "robotics engineer" },
  { name: "Risk Analyst", value: "risk analyst" },
  { name: "Renewable Energy Engineer", value: "renewable energy engineer" },
  { name: "Real Estate", value: "real estate" },
  { name: "Retail", value: "retail" },
  { name: "Social Impact Analyst", value: "social impact analyst" },
  { name: "Space Tourism Guide", value: "space tourism guide" },
  {
    name: "Sustainable Fashion Designer",
    value: "sustainable fashion designer",
  },
  { name: "Sharing Economy", value: "sharing economy" },
  { name: "Security and Surveillance", value: "security and surveillance" },
  { name: "Sports and Recreation", value: "sports and recreation" },
  { name: "Sales Representative", value: "sales representative" },
  { name: "Security Analyst", value: "security analyst" },
  { name: "Social Media Manager", value: "social media manager" },
  { name: "Software Developer", value: "software developer" },
  { name: "Systems Analyst", value: "systems analyst" },
  {
    name: "Technical Support Specialist",
    value: "technical support specialist",
  },
  { name: "Telecommunications", value: "telecommunications" },
  { name: "Transportation", value: "transportation" },
  { name: "Travel and Tourism", value: "travel and tourism" },
  { name: "Technical Writer", value: "technical writer" },
  { name: "Teacher", value: "teacher" },
  { name: "Tour Guide", value: "tour guide" },
  { name: "UI/UX Architect", value: "UI/UX architect" },
  { name: "UX/UI Researcher", value: "ux/ui researcher" },
  { name: "Underwriter", value: "underwriter" },
  { name: "Urban Planner", value: "urban planner" },
  { name: "Vascular Surgeon", value: "vascular surgeon" },
  { name: "Veterinarian", value: "veterinarian" },
  { name: "Virtual Reality Developer", value: "virtual reality developer" },
  {
    name: "Virtual and Augmented Reality",
    value: "virtual and augmented reality",
  },
  { name: "Veterinary", value: "veterinary" },
  { name: "Videographer", value: "videographer" },
  { name: "Welder", value: "welder" },
  { name: "Web Developer", value: "web developer" },
  { name: "Wildlife Biologist", value: "wildlife biologist" },
  { name: "X-ray Technician", value: "X-ray technician" },
  { name: "XML Developer", value: "XML developer" },
  { name: "Xenobiologist", value: "xenobiologist" },
  { name: "Xenobotanist", value: "xenobotanist" },
  { name: "Yoga Instructor", value: "yoga instructor" },
  { name: "Youth Counselor", value: "youth counselor" },
  { name: "Youth Mentor", value: "youth mentor" },
  { name: "Youth Program Coordinator", value: "youth program coordinator" },
  { name: "Zoologist", value: "zoologist" },
  { name: "Zookeeper", value: "zoologist" },
  { name: "Zumba Instructor", value: "zumba instructor" },
  { name: "Zipline Pilot", value: "zipline pilot" },
  { name: "Others", value: "others" },
];

export const GENDER = [
  { name: "Not Prefrerred", value: 0 },
  { name: "Male", value: 1 },
  { name: "Female", value: 2 },
  { name: "Non Binary", value: 3 },
  { name: "Intersex", value: 4 },
  { name: "Transgender", value: 5 },
];

export const ETHINICITY = [
  { name: "Asian", value: "asian" },
  { name: "African", value: "african" },
  { name: "North American", value: "north american" },
  { name: "South American", value: "south american" },
  { name: "Europian", value: "europian" },
  { name: "Australian", value: "australian" },
];

export const DATECREITERIA = [
  { name: "Weekly", value: "1" },
  { name: "Monthly", value: "2" },
  { name: "Yearly", value: "3" },
  { name: "Custom Dates", value: "4" },
];

export const DEPARTMENT = [
  { name: "Accounts", value: "accounts" },
  { name: "Business Development", value: "business development" },
  { name: "Finance", value: "finance" },
  { name: "Human Resource", value: "human resource" },
  { name: "Information Technology", value: "information technology" },
  { name: "Infrastructure", value: "infrastructure" },
  { name: "Manufacturing", value: "manufacturing" },
  { name: "Marketing", value: "marketing" },
  { name: "Operations Management", value: "operations management" },
  { name: "Sales", value: "sales" },
  { name: "Others", value: "others" },
];

export const MONTHS = [
  { name: "12 Months", value: 12 },
  { name: "24 Months", value: 24 },
  { name: "36 Months", value: 36 },
  { name: "48 Months", value: 48 },
];

export const SALESMAN = [
  { name: "Alex", value: "alex" },
  { name: "Methew", value: "methew" },
  { name: "John", value: "john" },
  { name: "Andrew", value: "andrew" },
];

export const CURRENCY = [
  { name: "EUR", icon: "€", value: "eur" },
  { name: "GBP", icon: "£", value: "gbp" },
  { name: "USD", icon: "$", value: "usd" },
  { name: "INR", icon: "₹", value: "inr" },
  { name: "CNY", icon: "¥", value: "cny" },
];

export const AGERANGE = [
  { name: "8-25", value: "1", content: { min: 8, max: 25 } },
  { name: "25-35", value: "2", content: { min: 25, max: 35 } },
  { name: "35-45", value: "3", content: { min: 35, max: 45 } },
  { name: "45-55", value: "4", content: { min: 45, max: 55 } },
  { name: "55-65", value: "5", content: { min: 55, max: 65 } },
  { name: "65-100", value: "6", content: { min: 65, max: 100 } },
];

export const ACCOUNT_TYPE = [
  { name: "Select subscription status", value: "" },
  { name: "Free", value: 1 },
  { name: "In Trial", value: 0 },
  { name: "Subscribed", value: 2 },
  { name: "Expired", value: 3 },
];

export const PER_PAGE = [
  { name: "10", value: 10 },
  { name: "25", value: 25 },
  { name: "50", value: 50 },
  { name: "100", value: 100 },
  { name: "150", value: 150 },
  { name: "200", value: 200 },
];

export const CONTENT_APPROVAL_STATUS = [
  { name: "Approve", value: 1 },
  { name: "Reject", value: 2 },
  { name: "Draft", value: 0 },
];

export const APPROVAL_STATUS_DROPDOWN = [
  { name: "Select approval status", value: "" },
  { name: "Approve", value: 1 },
  { name: "Reject", value: 2 },
  { name: "Draft", value: 0 },
];

export const STATUS = [
  { name: "Active", value: 1 },
  { name: "Inactive", value: 0 },
];

export const STATUS_FOR_DROPDOWN = [
  { name: "Select status", value: "" },
  { name: "Active", value: 1 },
  { name: "Inactive", value: 0 },
];

export const MEDIA_TYPE = [
  { name: "Audio", value: 1 },
  { name: "Video", value: 2 },
];

export const CONTENT_TYPE_WITH_TEXT = [
  { name: "Text", value: 1 },
  { name: "Image", value: 2 },
  { name: "Video", value: 3 },
];

export const MEDITATION_BY = [
  { name: "Shoorah", value: 1 },
  { name: "Expert", value: 2 },
];

export const FOCUS_TYPE = [
  { name: "Main", value: 1 },
  { name: "Affirmation", value: 2 },
];

export const FILTER_FOCUS_TYPE = [
  { name: "Select focus type", value: "" },
  { name: "Main", value: 1 },
  { name: "Affirmation", value: 2 },
];

export const AFFIRMATION_TYPE = [
  { name: "CSV", value: 1 },
  { name: "Manual", value: 2 },
];

export const sentToUser = [
  { name: "All", value: 1 },
  { name: "In Trial", value: 2 },
  { name: "Subscribed", value: 3 },
  { name: "Expired", value: 5 },
  { name: "Custom Users List", value: 4 },
  { name: "Not Subscribed", value: 6 },
];

export const CONTENT_TYPE = [
  { name: "Select content type", value: "" },
  { name: "Focus", value: 1 },
  { name: "Affirmation", value: 2 },
  { name: "Meditation", value: 3 },
  { name: "Sound", value: 4 },
  { name: "Shoorah Pod", value: 5 },
  { name: "Gratitude", value: 6 },
  { name: "Rituals", value: 7 },
];

export const TUTORIAL_CONTENT_TYPE = {
  ABOUT_SHOORAH: 0,
  CLEANSE: 1,
  GRATITUDE: 2,
  GOALS: 3,
  RITUALS: 4,
  NOTES: 5,
  RESTORE: 6,
  JOURNAL: 7,
  AFFIRMATION: 8,
  PODS: 9,
  SHURU: 10,
};

export const BOOKMARKS_CONTENT_TYPE = {
  FOCUS: 1,
  AFFIRMATION: 2,
  MEDITATION: 3,
  SLEEP: 4,
  SHOORAH_PODS: 5,
  GRATITUDE: 6,
  RITUALS: 7,
};

export const CONTENT_APPROVAL_TYPE = [
  { name: "Latest version", value: 2 },
  { name: "Previous version", value: 1 },
];

export const PERFORMANCE_CONTENT_TYPE = [
  { name: "Cleanse", value: 1 },
  { name: "Gratitude", value: 2 },
  { name: "Goals", value: 3 },
  { name: "Rituals", value: 4 },
  { name: "Notes", value: 5 },
  { name: "Badges", value: 6 },
];

export const USER_FEELS = [
  { name: "Daily", value: 1 },
  { name: "Weekly", value: 2 },
  { name: "Monthly", value: 3 },
  { name: "Yearly", value: 4 },
];

export const COLORS = [
  "rgba(218, 60, 60, 0.7)",
  "rgba(235, 128, 50, 0.7)",
  "rgba(133, 225, 61, 0.7)",
  "rgba(61, 225, 186, 0.7)",
  "rgba(227, 221, 57, 0.7)",
  "rgba(139, 60, 218, 0.7)",
  "rgba(50, 57, 235, 0.7)",
  "rgba(183, 61, 225, 0.7)",
  "rgba(128, 144, 203, 0.7)",
  "rgba(158, 36, 139, 0.7)",
  "rgba(54, 169, 251, 0.7)",
  "rgba(254, 170, 95, 0.7)",
  "rgba(245, 105, 107, 0.7)",
  "rgba(168, 71, 94, 0.7)",
  "rgba(188, 143, 176, 0.7)",
  "rgba(243, 217, 147, 0.7)",
  "rgba(141, 0, 9, 0.7)",
  "rgba(253, 80, 26, 0.7)",
  "rgba(207, 210, 58, 0.7)",
  "rgba(155, 182, 196, 0.7)",
  "rgba(93, 116, 97, 0.7)",
  "rgba(71, 142, 15, 0.7)",
  "rgba(248, 96, 124, 0.7)",
  "rgba(137, 172, 249, 0.7)",
  "rgba(251, 240, 111, 0.7)",
];
