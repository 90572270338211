import React, { Fragment, useEffect, useState } from "react";
import { Api } from "../../api";
import { errorToast } from "../../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import Setting from "./setting";
import Lottie from "lottie-react";
import ConfirmPopup from "../../component/common/modals/ConfirmPopup";
import messageLogo from "../../assets/images/message.svg";
import { useTheme } from "../../contents/context/themeContext";
import moment from "moment";

const ShuruHome = ({ tab, setTab, setShow, setShowEndBox }) => {
  const { theme } = useTheme();
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [sessionStartTime, setSessionStartTime] = useState(null);

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return setSessionStartTime(decodeURIComponent(cookie[1]));
      }
    }
    return setSessionStartTime(null);
  };

  useEffect(() => {
    getCookie("sessionTime");
  }, []);

  useEffect(() => {
    if (sessionStartTime) {
      const interval = setInterval(() => {
        const currentDate = moment();
        let date = sessionStartTime;
        const dateString = date.replace(/"/g, ""); // Remove the quotes
        const dateObject = new Date(dateString);
        const targetDate = moment(dateObject);

        // Check if the parsed date is valid
        if (!targetDate.isValid()) {
          // console.error('Invalid date format');
          return;
        }
        const differenceInSeconds = targetDate.diff(currentDate, "seconds");

        if (differenceInSeconds == -60) {
          setShow(true);
        }
        if (differenceInSeconds <= -86400) {
          setShowEndBox(true);
          // clearInterval(interval);
        }
        // console.log('Check', differenceInSeconds)
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, [sessionStartTime]);

  return (
    <div
      className={` relative flex h-screen w-full flex-col items-center gap-y-12 overflow-hidden lg:gap-y-8`}
    >
      <div className="mt-12 flex h-auto w-full flex-col items-center justify-center gap-y-2  text-center lg:mt-0 lg:h-[50%]">
        <img
          src={theme.shuruMain}
          className=" h-48 w-48 xl:h-[15rem] xl:w-[15rem]"
        />
      </div>

      <div className="  relative flex h-full w-screen flex-grow items-center justify-center lg:flex-grow-0 ">
        <div
          // style={{ borderRadius: "50% 50% 0 0/90% 90% 0 0" }}
          className={`absolute bottom-[-5px] left-0 z-20 flex h-full w-full scale-x-150 flex-col items-center justify-center rounded-t-[50%] text-white  ${theme.shoorah_bg_5}`}
        ></div>

        <div className="relative z-20 h-full  w-full ">
          <div className="flex h-full w-full items-center justify-center">
            <div className="relative z-20 flex h-full w-full flex-col justify-center px-4 text-white   xl:mr-[2rem] xl:w-[50%] xl:px-0 2xl:mr-[3rem]">
              <div className="flex flex-col gap-[1rem] lg:gap-[2rem]">
                <p className="P22Mackinac  text-center text-4xl font-medium lg:text-[3rem]  2xl:text-[3.5rem]">
                  Hello
                </p>
                <p className="mx-auto w-full px-9 text-center text-base lg:px-0 lg:text-xl">
                  I’m Shuru, Shoorah’s trusty guru,
                  <br className="hidden lg:flex" /> I am here to help you!
                </p>
              </div>
              <div className="mt-[2rem]  flex flex-col items-center justify-center gap-4 xl:flex-row xl:gap-[5rem]">
                <button
                  onClick={() => {
                    setTab(2);
                  }}
                  className="flex items-center justify-center gap-4 outline-none xl:gap-6"
                >
                  <div className="flex h-10 w-10 items-center justify-center  rounded-full  border-2 p-[0.4rem] xl:h-10 xl:w-10 xl:border-[3px]">
                    <p className="">
                      <img
                        className="h-full w-full"
                        id="chat"
                        src={messageLogo}
                        alt="message"
                      />
                    </p>
                  </div>
                  <p className="P22Mackinac text-center text-lg font-medium">
                    Start New Chat
                  </p>
                  <p className="rotate-180">
                    <svg
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <g id="arrow-left-1--arrow-keyboard-left">
                        <path
                          id="Vector 2"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m1 7 12 0"
                        ></path>
                        <path
                          id="Vector"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.996 3C3 4 2 5 1 7c1 2 2 3 3.996 4"
                        ></path>
                      </g>
                    </svg>
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShuruHome;
