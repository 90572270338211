import React, { createContext, useContext, useEffect, useState } from "react";
import {
  bloom,
  desert,
  land,
  ocean,
  shoorah,
  sun,
} from "../../assets/svg/themes/themesColorCodes/shoorah";
import shoorahMusicShapeLottie from "../../assets/lottie/lottie_star_shape_shoorah.json";
import landMusicShapeLottie from "../../assets/lottie/lottie_star_shape_land.json";
import bloomMusicShapeLottie from "../../assets/lottie/lottie_star_shape_bloom.json";
import DesertMusicShapeLottie from "../../assets/lottie/lottie_star_shape_desert.json";
import OceanMusicShapeLottie from "../../assets/lottie/lottie_star_shape_ocean.json";
import SunMusicShapeLottie from "../../assets/lottie/lottie_star_shape_sun.json";

import meShoorah from "../../assets/images/img_avtar.png";
import meLand from "../../assets/images/img_avtar_land.png";
import meBloom from "../../assets/images/img_avtar_bloom.png";
import meDesert from "../../assets/images/img_avtar_desert.png";
import meOcean from "../../assets/images/img_avtar_ocean.png";
import meSun from "../../assets/images/img_avtar_sun.png";

import macShoorah from "../../assets/themeMac/Shoorah_Macbook_ShoorahTone_v1.1.png";
import macLand from "../../assets/themeMac/Shoorah_Macbook_LandTone_v1.1.png";
import macBloom from "../../assets/themeMac/Shoorah_Macbook_BloomTone_v1.1.png";
import macSun from "../../assets/themeMac/Shoorah_Macbook_SunTone_v1.1.png";
import macDessert from "../../assets/themeMac/Shoorah_Macbook_DesertTone_v1.1.png";
import macOcean from "../../assets/themeMac/Shoorah_Macbook_OceamTone_v1.1.png";

import phoneShoorah from "../../assets/phone_images/phone_shoorah.png";
import phoneLand from "../../assets/phone_images/phone_land.png";
import phoneBloom from "../../assets/phone_images/phone_bloom.png";
import phoneSun from "../../assets/phone_images/phone_sun.png";
import phoneDessert from "../../assets/phone_images/phone_desert.png";
import phoneOcean from "../../assets/phone_images/phone_ocean.png";

import shoorahAudio from "../../assets/images/img_shoorah_bottom_bar.png";
import landAudio from "../../assets/images/img_land_bottom_bar.png";
import bloomAudio from "../../assets/images/img_bloom_bottom_bar.png";
import sunAudio from "../../assets/images/img_sun_bottom_bar.png";
import dessertAudio from "../../assets/images/img_desert_bottom_bar.png";
import oceanAudio from "../../assets/images/img_ocean_bottom_bar.png";

export const themes = [
  {
    name: "Shoorah",
    shoorah_hard_gradient: shoorah.gradient_heavy,
    shoorah_medium_gradient: shoorah.gradient_medium,
    shoorah_light_gradient: shoorah.gradient_light,
    shoorah_feather_gradient: shoorah.gradient_feather,
    badge_grad: shoorah.badge_grad,

    shoorah_mac: macShoorah,
    shoorah_phone: phoneShoorah,
    shoorah_audio: shoorahAudio,

    shoorahMusicShapeLottie: shoorahMusicShapeLottie,

    shoorah_circle: shoorah.shoorah_circle,

    shoorahShapeColor: shoorah.shapeColor,
    shuruMain: shoorah.shuruMain,
    shuruMe: meShoorah,

    shoorah_hero_personalize: shoorah.personalize,
    shoorah_hero_notify: shoorah.notify,
    shoorah_hero_info: shoorah.info,

    shoorah_home_active: shoorah.home_active,
    shoorah_home_in_active: shoorah.home_in_active,
    shoorah_journal_active: shoorah.journal_active,
    shoorah_journal_in_active: shoorah.journal_in_active,
    shoorah_rituals_active: shoorah.rituals_active,
    shoorah_rituals_in_active: shoorah.rituals_in_active,
    shoorah_restore_active: shoorah.restore_active,
    shoorah_restore_in_active: shoorah.restore_in_active,
    shoorah_explore_active: shoorah.explore_active,
    shoorah_explore_in_active: shoorah.explore_in_active,

    shoorah_box_shape: shoorah.box_shape,
    shoorah_star_shape: shoorah.star_shape,
    shoorah_edge_shape: shoorah.edge_box,
    shoorah_circle_shape: shoorah.circle_shape,

    shoorah_add_image_icon: shoorah.add_image_icon,
    shoorah_title_icon: shoorah.title_icon,
    shoorah_description_icon: shoorah.description_icon,

    shuruContent: shoorah.shuruContent,
    shuruAngry: shoorah.shuruAngry,
    shuruAnxious: shoorah.shuruAnxious,
    shuruExcited: shoorah.shuruExcited,
    shuruHappy: shoorah.shuruHappy,
    shuruStressed: shoorah.shuruStressed,
    shuruTired: shoorah.shuruTired,
    shuruSad: shoorah.shuruSad,
    shuruSurprised: shoorah.shuruSurprised,
    shuruStrokeContent: shoorah.shuruStrokeContent,
    shuruStrokeAngry: shoorah.shuruStrokeAngry,
    shuruStrokeAnxious: shoorah.shuruStrokeAnxious,
    shuruStrokeExcited: shoorah.shuruStrokeExcited,
    shuruStrokeHappy: shoorah.shuruStrokeHappy,
    shuruStrokeTired: shoorah.shuruStrokeTired,
    shuruStrokeSad: shoorah.shuruStrokeSad,
    shuruStrokeSurprised: shoorah.shuruStrokeSurprised,

    shuruContentLotie: shoorah.shuruContentLotie,
    shuruAngryLotie: shoorah.shuruAngryLotie,
    shuruAnxiousLotie: shoorah.shuruAnxiousLotie,
    shuruSurpriseLotie: shoorah.shuruSurpriseLotie,
    shuruWorriedLotie: shoorah.shuruWorriedLotie,
    shuruCryLotie: shoorah.shuruCryLotie,
    shuruHappyLotie: shoorah.shuruHappyLotie,
    shuruSleepLotie: shoorah.shuruSleepLotie,
    shuruExcitedLotie: shoorah.shuruExcitedLotie,

    shuruProfile: shoorah.shuruProfile,
    shuruLeave: shoorah.shuruLeave,

    audioBar1: `bg-gradient-to-r from-[#18202f] to-[#313b6b]`,

    shoorah_1: `#e5eaf9`,
    shoorah_2: `#B6C0F3`,
    shoorah_3: `#888beb`,
    shoorah_4: `#6167e8`,
    shoorah_5: `#4a56db`,
    shoorah_6: `#3a47ab`,
    shoorah_7: `#313b6b`,
    shoorah_8: `#18202f`,

    shoorah_range_1: `accent-[#e5eaf9]`,
    shoorah_range_2: `accent-[#B6C0F3]`,
    shoorah_range_3: `accent-[#888beb]`,
    shoorah_range_4: `accent-[#6167e8]`,
    shoorah_range_5: `accent-[#4a56db]`,
    shoorah_range_6: `accent-[#3a47ab]`,
    shoorah_range_7: `accent-[#313b6b]`,
    shoorah_range_8: `accent-[#18202f]`,

    shoorah_text_1: `text-[#e5eaf9]`,
    shoorah_text_2: `text-[#B6C0F3]`,
    shoorah_text_3: `text-[#888beb]`,
    shoorah_text_4: `text-[#6167e8]`,
    shoorah_text_5: `text-[#4a56db]`,
    shoorah_text_6: `text-[#3a47ab]`,
    shoorah_text_7: `text-[#313b6b]`,
    shoorah_text_8: `text-[#18202f]`,

    shoorah_text_hover_1: `hover:text-[#e5eaf9]`,
    shoorah_text_hover_2: `hover:text-[#B6C0F3]`,
    shoorah_text_hover_3: `hover:text-[#888beb]`,
    shoorah_text_hover_4: `hover:text-[#6167e8]`,
    shoorah_text_hover_5: `hover:text-[#4a56db]`,
    shoorah_text_hover_6: `hover:text-[#3a47ab]`,
    shoorah_text_hover_7: `hover:text-[#313b6b]`,
    shoorah_text_hover_8: `hover:text-[#18202f]`,

    shoorah_bg_1: `bg-[#e5eaf9]`,
    shoorah_bg_2: `bg-[#B6C0F3]`,
    shoorah_bg_3: `bg-[#888beb]`,
    shoorah_bg_4: `bg-[#6167e8]`,
    shoorah_bg_5: `bg-[#4a56db]`,
    shoorah_bg_6: `bg-[#3a47ab]`,
    shoorah_bg_7: `bg-[#313b6b]`,
    shoorah_bg_8: `bg-[#18202f]`,

    shoorah_fill_1: `fill-[#e5eaf9]`,
    shoorah_fill_2: `fill-[#B6C0F3]`,
    shoorah_fill_3: `fill-[#888beb]`,
    shoorah_fill_4: `fill-[#6167e8]`,
    shoorah_fill_5: `fill-[#4a56db]`,
    shoorah_fill_6: `fill-[#3a47ab]`,
    shoorah_fill_7: `fill-[#313b6b]`,
    shoorah_fill_8: `fill-[#18202f]`,

    shoorah_bg_hover_1: `hover:bg-[#e5eaf9]`,
    shoorah_bg_hover_2: `hover:bg-[#B6C0F3]`,
    shoorah_bg_hover_3: `hover:bg-[#888beb]`,
    shoorah_bg_hover_4: `hover:bg-[#6167e8]`,
    shoorah_bg_hover_5: `hover:bg-[#4a56db]`,
    shoorah_bg_hover_6: `hover:bg-[#3a47ab]`,
    shoorah_bg_hover_7: `hover:bg-[#313b6b]`,
    shoorah_bg_hover_8: `hover:bg-[#18202f]`,

    shoorah_border_1: `border-[#e5eaf9]`,
    shoorah_border_2: `border-[#B6C0F3]`,
    shoorah_border_3: `border-[#888beb]`,
    shoorah_border_4: `border-[#6167e8]`,
    shoorah_border_5: `border-[#4a56db]`,
    shoorah_border_6: `border-[#3a47ab]`,
    shoorah_border_7: `border-[#313b6b]`,
    shoorah_border_8: `border-[#18202f]`,

    shoorah_border_ring_focus_1: `focus:ring-[#e5eaf9]`,
    shoorah_border_ring_focus_2: `focus:ring-[#B6C0F3]`,
    shoorah_border_ring_focus_3: `focus:ring-[#888beb]`,
    shoorah_border_ring_focus_4: `focus:ring-[#6167e8]`,
    shoorah_border_ring_focus_5: `focus:ring-[#4a56db]`,
    shoorah_border_ring_focus_6: `focus:ring-[#3a47ab]`,
    shoorah_border_ring_focus_7: `focus:ring-[#313b6b]`,
    shoorah_border_ring_focus_8: `focus:ring-[#18202f]`,

    shoorah_border_focus_1: `focus:border-[#e5eaf9]`,
    shoorah_border_focus_2: `focus:border-[#B6C0F3]`,
    shoorah_border_focus_3: `focus:border-[#888beb]`,
    shoorah_border_focus_4: `focus:border-[#6167e8]`,
    shoorah_border_focus_5: `focus:border-[#4a56db]`,
    shoorah_border_focus_6: `focus:border-[#3a47ab]`,
    shoorah_border_focus_7: `focus:border-[#313b6b]`,
    shoorah_border_focus_8: `focus:border-[#18202f]`,

    shoorah_shadow_1: `shadow-[#e5eaf9]`,
    shoorah_shadow_2: `shadow-[#B6C0F3]`,
    shoorah_shadow_3: `shadow-[#888beb]`,
    shoorah_shadow_4: `shadow-[#6167e8]`,
    shoorah_shadow_5: `shadow-[#4a56db]`,
    shoorah_shadow_6: `shadow-[#3a47ab]`,
    shoorah_shadow_7: `shadow-[#313b6b]`,
    shoorah_shadow_8: `shadow-[#18202f]`,

    shoorah_inactive_icons: `text-[#9399b1]`,
    strokeColor: `#313B6B`,
    strokeColor2: `#313B6B`,
    strokeColor3: `#4a56db`,
    moodBg: `bg-[#18202F]`,

    shoorah_focus_gradient: `bg-gradient-to-br from-[#DFDBFF] via-[#E7EAFF] via-[#D3DAFF] to-[#D3DAFF]`,

    sidebarBg: `bg-[#fff]`,
    tickColor: `#000`,

    color: `bg-gradient-to-t from-[#dad4ff] via-[#795cfa] to-[#edeeff]`,
    toggletext: `text-[#4a56da]`,
    toggle_bg: `bg-[#e5eaf9]`,
    inactiveToggleText: `text-[#9399b2]`,
    inactiveToggleBg: `bg-[]`,
    audioPlayButton: `text-shoorah-secondary`,
    soundBorder: `border-[#635a78]`,
    meButton: `bg-[#6167e8]`,
    heroGradient: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    heroCircle: `bg-[#b1a0fe]`,
    addCircle: `border-[#4a55da]`,
    addCircleText: `text-[#4a55da]`,
    shareCircle: `bg-[#4a55da]`,
    affirBg: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    affirBtns: `bg-[#4a56da]`,
    gradtitudeImage: `text-[#313b6b]`,
    gratitudeShadow: `shadow-[#4d5266]`,
    chatBotBg: `bg-[#6167e7]`,
    chatBotCircle: `bg-[#b6c0f3]`,
    chatNowText: `text-[#6167e7]`,
    dailyRitualBg: `bg-[#b6c0f3]`,
    dailyRitualBgDone: `bg-[#888beb]`,
    heroGradient2: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    borderFocus: `focus:border-shoorah-primary`,
    borderRingFocus: `focus:ring-shoorah-primary`,
    addBtnBg: `bg-[#6167e8]`,
    imageCardBg: `bg-[#6167e7]`,
    confirmMarkBg: `bg-blue-100`,
    qMark: `text-shoorah-secondary`,
    bgPrimary: `bg-shoorah-primary`,
    textPrimary: `text-shoorah-primary`,
    textSecondary: `text-shoorah-secondary`,
    bgSecondary: `bg-shoorah-secondary`,
    bgSecondaryHover: `hover:bg-shoorah-secondary`,
    previewImg: `bg-[#acb1ea]`,
    previewIcon: `text-[#4a56da]`,
    previewBorder: `border-[#4a56da]`,
    errorMsg: `text-shoorah-primary`,
    textMsg: `text-[red]`,
    primarytosecondary: `bg-gradient-to-r from-shoorah-primary to-shoorah-secondary`,
    bgPrimaryHover: `bg-shoorah-primary`,
    subsBg: `bg-[#151852]`,
    subsText: `text-[#151852]`,
    subsBorder: `border-[blue]`,
    subsDiv: `bg-[#d9dafa]`,
    podsBg: `bg-black`,
    podsCircle: `bg-shoorah-primary`,
    podsText: `text-shoorah-primary`,
  },
  {
    name: "Land",
    shoorah_hard_gradient: land.gradient_heavy,
    shoorah_light_gradient: land.gradient_light,
    shoorah_feather_gradient: land.gradient_feather,
    shoorah_medium_gradient: land.gradient_medium,
    badge_grad: land.badge_grad,

    shoorah_audio: landAudio,
    shoorah_phone: phoneLand,

    shoorah_mac: macLand,

    shoorahMusicShapeLottie: landMusicShapeLottie,
    shuruMain: land.shuruMain,

    shoorah_circle: land.shoorah_circle,
    shuruMe: meLand,

    shoorahShapeColor: land.shapeColor,

    shoorah_hero_personalize: land.personalize,
    shoorah_hero_notify: land.notify,
    shoorah_hero_info: land.info,

    shoorah_home_active: land.home_active,
    shoorah_home_in_active: land.home_in_active,
    shoorah_journal_active: land.journal_active,
    shoorah_journal_in_active: land.journal_in_active,
    shoorah_rituals_active: land.rituals_active,
    shoorah_rituals_in_active: land.rituals_in_active,
    shoorah_restore_active: land.restore_active,
    shoorah_restore_in_active: land.restore_in_active,
    shoorah_explore_active: land.explore_active,
    shoorah_explore_in_active: land.explore_in_active,

    shoorah_box_shape: land.box_shape,
    shoorah_star_shape: land.star_shape,
    shoorah_edge_shape: land.edge_box,
    shoorah_circle_shape: land.circle_shape,

    shoorah_add_image_icon: land.add_image_icon,
    shoorah_title_icon: land.title_icon,
    shoorah_description_icon: land.description_icon,

    shuruContent: land.shuruContent,
    shuruAngry: land.shuruAngry,
    shuruAnxious: land.shuruAnxious,
    shuruExcited: land.shuruExcited,
    shuruHappy: land.shuruHappy,
    shuruStressed: land.shuruStressed,
    shuruTired: land.shuruTired,
    shuruSad: land.shuruSad,
    shuruSurprised: land.shuruSurprised,
    shuruStrokeContent: land.shuruStrokeContent,
    shuruStrokeAngry: land.shuruStrokeAngry,
    shuruStrokeAnxious: land.shuruStrokeAnxious,
    shuruStrokeExcited: land.shuruStrokeExcited,
    shuruStrokeHappy: land.shuruStrokeHappy,
    shuruStrokeTired: land.shuruStrokeTired,
    shuruStrokeSad: land.shuruStrokeSad,
    shuruStrokeSurprised: land.shuruStrokeSurprised,

    shuruContentLotie: land.shuruContentLotie,
    shuruAngryLotie: land.shuruAngryLotie,
    shuruAnxiousLotie: land.shuruAnxiousLotie,
    shuruSurpriseLotie: land.shuruSurpriseLotie,
    shuruWorriedLotie: land.shuruWorriedLotie,
    shuruCryLotie: land.shuruCryLotie,
    shuruHappyLotie: land.shuruHappyLotie,
    shuruSleepLotie: land.shuruSleepLotie,
    shuruExcitedLotie: land.shuruExcitedLotie,

    shuruProfile: land.shuruProfile,
    shuruLeave: land.shuruLeave,

    audioBar1: `bg-gradient-to-r from-[#112911] to-[#67a14a]`,

    shoorah_1: `#f1fae3`,
    shoorah_2: `#ddf2bf`,
    shoorah_3: `#c2ec97`,
    shoorah_4: `#8fd460`,
    shoorah_5: `#67a14a`,
    shoorah_6: `#438038`,
    shoorah_7: `#2e6630`,
    shoorah_8: `#112911`,

    shoorah_range_1: `accent-[#f1fae3]`,
    shoorah_range_2: `accent-[#ddf2bf]`,
    shoorah_range_3: `accent-[#c2ec97]`,
    shoorah_range_4: `accent-[#8fd460]`,
    shoorah_range_5: `accent-[#67a14a]`,
    shoorah_range_6: `accent-[#438038]`,
    shoorah_range_7: `accent-[#2e6630]`,
    shoorah_range_8: `accent-[#112911]`,

    shoorah_text_1: `text-[#f1fae3]`,
    shoorah_text_2: `text-[#ddf2bf]`,
    shoorah_text_3: `text-[#c2ec97]`,
    shoorah_text_4: `text-[#8fd460]`,
    shoorah_text_5: `text-[#67a14a]`,
    shoorah_text_6: `text-[#438038]`,
    shoorah_text_7: `text-[#2e6630]`,
    shoorah_text_8: `text-[#112911]`,

    shoorah_text_hover_1: `hover:text-[#f1fae3]`,
    shoorah_text_hover_2: `hover:text-[#ddf2bf]`,
    shoorah_text_hover_3: `hover:text-[#c2ec97]`,
    shoorah_text_hover_4: `hover:text-[#8fd460]`,
    shoorah_text_hover_5: `hover:text-[#67a14a]`,
    shoorah_text_hover_6: `hover:text-[#438038]`,
    shoorah_text_hover_7: `hover:text-[#2e6630]`,
    shoorah_text_hover_8: `hover:text-[#112911]`,

    shoorah_bg_1: `bg-[#f1fae3]`,
    shoorah_bg_2: `bg-[#ddf2bf]`,
    shoorah_bg_3: `bg-[#c2ec97]`,
    shoorah_bg_4: `bg-[#8fd460]`,
    shoorah_bg_5: `bg-[#67a14a]`,
    shoorah_bg_6: `bg-[#438038]`,
    shoorah_bg_7: `bg-[#2e6630]`,
    shoorah_bg_8: `bg-[#112911]`,

    shoorah_fill_1: `fill-[#f1fae3]`,
    shoorah_fill_2: `fill-[#ddf2bf]`,
    shoorah_fill_3: `fill-[#c2ec97]`,
    shoorah_fill_4: `fill-[#8fd460]`,
    shoorah_fill_5: `fill-[#67a14a]`,
    shoorah_fill_6: `fill-[#438038]`,
    shoorah_fill_7: `fill-[#2e6630]`,
    shoorah_fill_8: `fill-[#112911]`,

    shoorah_bg_hover_1: `hover:bg-[#f1fae3]`,
    shoorah_bg_hover_2: `hover:bg-[#ddf2bf]`,
    shoorah_bg_hover_3: `hover:bg-[#c2ec97]`,
    shoorah_bg_hover_4: `hover:bg-[#8fd460]`,
    shoorah_bg_hover_5: `hover:bg-[#67a14a]`,
    shoorah_bg_hover_6: `hover:bg-[#438038]`,
    shoorah_bg_hover_7: `hover:bg-[#2e6630]`,
    shoorah_bg_hover_8: `hover:bg-[#112911]`,

    shoorah_border_1: `border-[#f1fae3]`,
    shoorah_border_2: `border-[#ddf2bf]`,
    shoorah_border_3: `border-[#c2ec97]`,
    shoorah_border_4: `border-[#8fd460]`,
    shoorah_border_5: `border-[#67a14a]`,
    shoorah_border_6: `border-[#438038]`,
    shoorah_border_7: `border-[#2e6630]`,
    shoorah_border_8: `border-[#112911]`,

    shoorah_border_ring_focus_1: `focus:ring-[#f1fae3]`,
    shoorah_border_ring_focus_2: `focus:ring-[#ddf2bf]`,
    shoorah_border_ring_focus_3: `focus:ring-[#c2ec97]`,
    shoorah_border_ring_focus_4: `focus:ring-[#8fd460]`,
    shoorah_border_ring_focus_5: `focus:ring-[#67a14a]`,
    shoorah_border_ring_focus_6: `focus:ring-[#438038]`,
    shoorah_border_ring_focus_7: `focus:ring-[#2e6630]`,
    shoorah_border_ring_focus_8: `focus:ring-[#112911]`,

    shoorah_border_focus_1: `focus:border-[#f1fae3]`,
    shoorah_border_focus_2: `focus:border-[#ddf2bf]`,
    shoorah_border_focus_3: `focus:border-[#c2ec97]`,
    shoorah_border_focus_4: `focus:border-[#8fd460]`,
    shoorah_border_focus_5: `focus:border-[#67a14a]`,
    shoorah_border_focus_6: `focus:border-[#438038]`,
    shoorah_border_focus_7: `focus:border-[#2e6630]`,
    shoorah_border_focus_8: `focus:border-[#112911]`,

    shoorah_shadow_1: `shadow-[#f1fae3]`,
    shoorah_shadow_2: `shadow-[#ddf2bf]`,
    shoorah_shadow_3: `shadow-[#c2ec97]`,
    shoorah_shadow_4: `shadow-[#8fd460]`,
    shoorah_shadow_5: `shadow-[#67a14a]`,
    shoorah_shadow_6: `shadow-[#438038]`,
    shoorah_shadow_7: `shadow-[#2e6630]`,
    shoorah_shadow_8: `shadow-[#112911]`,

    shoorah_inactive_icons: `text-[#9399b1]`,
    strokeColor: `#2e6630`,
    strokeColor2: `#2e6630`,
    strokeColor3: `#000`,
    moodBg: `bg-[#183218]`,

    shoorah_focus_gradient: `bg-gradient-to-br from-[#D9EACA] via-[#E2F4D2] via-[#CEE7BA] to-[#F1FDE9]`,

    tickColor: `#fff`,
    color: `bg-gradient-to-t from-[#baf7b9] via-[#44d741] to-[#7acf78]`,
    toggletext: `text-[#156722]`,
    toggle_bg: `bg-[#94eba2]`,
    inactiveToggleText: `text-[#9399b2]`,
    inactiveToggleBg: `bg-[]`,
    audioPlayButton: `text-[green]`,
    soundBorder: `border-[#54da4a]`,
    meButton: `bg-[#77e861]`,
    sidebarBg: `bg-[#fff]`,
    sidebarText: `text-[#54da4a]`,
    heroGradient: `bg-gradient-to-r from-[#daffd4] to-[#efffed]`,
    heroCircle: `bg-[#a9fea0]`,
    addCircle: `border-[#4dda4a]`,
    addCircleText: `text-[#4dda4a]`,
    shareCircle: `bg-[#4dda4a]`,
    affirBg: `bg-gradient-to-r from-[#d4ffd5] to-[#efffed]`,
    affirBtns: `bg-[#54da4a]`,
    gradtitudeImage: `text-[#356b31]`,
    gratitudeShadow: `shadow-[#4d664e]`,
    chatBotBg: `bg-[#64e761]`,
    chatBotCircle: `bg-[#bff3b6]`,
    chatNowText: `text-[#77e761]`,
    dailyRitualBg: `bg-[#b9f3b6]`,
    dailyRitualBgDone: `bg-[#8deb88]`,
    heroGradient2: `bg-gradient-to-r from-[#ddffd4] to-[#efffed]`,
    borderFocus: `focus:border-[#4dda4a]`,
    borderRingFocus: `focus:ring-[#4dda4a]`,
    addBtnBg: `bg-[#6ee861]`,
    imageCardBg: `bg-[#6ee860]`,
    confirmMarkBg: `bg-green-100`,
    qMark: `text-[green]`,
    bgPrimary: `bg-[#4dda4a]`,
    textPrimary: `text-[#4dda4a]`,
    textSecondary: `text-[green]`,
    bgSecondary: `bg-[green]`,
    bgSecondaryHover: `hover:bg-[green]`,
    previewImg: `bg-[#bbeaac]`,
    previewIcon: `text-[#5eda4a]`,
    previewBorder: `border-[#5eda4a]`,
    errorMsg: `text-green-800`,
    textMsg: `text-green-800`,
    primarytosecondary: `bg-gradient-to-r from-[#4dda4a] to-[green]`,
    bgPrimaryHover: `bg-[#4dda4a]`,
    subsBg: `bg-[#205215]`,
    subsText: `text-[#205215]`,
    subsBorder: `border-[green]`,
    subsDiv: `bg-[#dbfad9]`,
  },
  {
    name: "Bloom",
    shoorah_hard_gradient: bloom.gradient_heavy,
    shoorah_light_gradient: bloom.gradient_light,
    shoorah_feather_gradient: bloom.gradient_feather,
    shoorah_medium_gradient: bloom.gradient_medium,
    badge_grad: bloom.badge_grad,

    shoorah_mac: macBloom,
    shoorah_audio: bloomAudio,
    shoorah_phone: phoneBloom,

    shoorahMusicShapeLottie: bloomMusicShapeLottie,

    shoorah_circle: bloom.shoorah_circle,
    shuruMain: bloom.shuruMain,

    shoorahShapeColor: bloom.shapeColor,
    shuruMe: meBloom,

    shoorah_hero_personalize: bloom.personalize,
    shoorah_hero_notify: bloom.notify,
    shoorah_hero_info: bloom.info,

    shoorah_home_active: bloom.home_active,
    shoorah_home_in_active: bloom.home_in_active,
    shoorah_journal_active: bloom.journal_active,
    shoorah_journal_in_active: bloom.journal_in_active,
    shoorah_rituals_active: bloom.rituals_active,
    shoorah_rituals_in_active: bloom.rituals_in_active,
    shoorah_restore_active: bloom.restore_active,
    shoorah_restore_in_active: bloom.restore_in_active,
    shoorah_explore_active: bloom.explore_active,
    shoorah_explore_in_active: bloom.explore_in_active,

    shoorah_box_shape: bloom.box_shape,
    shoorah_star_shape: bloom.star_shape,
    shoorah_edge_shape: bloom.edge_box,
    shoorah_circle_shape: bloom.circle_shape,

    shoorah_add_image_icon: bloom.add_image_icon,
    shoorah_title_icon: bloom.title_icon,
    shoorah_description_icon: bloom.description_icon,

    shuruContent: bloom.shuruContent,
    shuruAngry: bloom.shuruAngry,
    shuruAnxious: bloom.shuruAnxious,
    shuruExcited: bloom.shuruExcited,
    shuruHappy: bloom.shuruHappy,
    shuruStressed: bloom.shuruStressed,
    shuruTired: bloom.shuruTired,
    shuruSad: bloom.shuruSad,
    shuruSurprised: bloom.shuruSurprised,
    shuruStrokeContent: bloom.shuruStrokeContent,
    shuruStrokeAngry: bloom.shuruStrokeAngry,
    shuruStrokeAnxious: bloom.shuruStrokeAnxious,
    shuruStrokeExcited: bloom.shuruStrokeExcited,
    shuruStrokeHappy: bloom.shuruStrokeHappy,
    shuruStrokeTired: bloom.shuruStrokeTired,
    shuruStrokeSad: bloom.shuruStrokeSad,
    shuruStrokeSurprised: bloom.shuruStrokeSurprised,

    shuruContentLotie: bloom.shuruContentLotie,
    shuruAngryLotie: bloom.shuruAngryLotie,
    shuruAnxiousLotie: bloom.shuruAnxiousLotie,
    shuruSurpriseLotie: bloom.shuruSurpriseLotie,
    shuruWorriedLotie: bloom.shuruWorriedLotie,
    shuruCryLotie: bloom.shuruCryLotie,
    shuruHappyLotie: bloom.shuruHappyLotie,
    shuruSleepLotie: bloom.shuruSleepLotie,
    shuruExcitedLotie: bloom.shuruExcitedLotie,

    shuruProfile: bloom.shuruProfile,
    shuruLeave: bloom.shuruLeave,

    audioBar1: `bg-gradient-to-r from-[#381e23] to-[#f05289]`,

    shoorah_1: `#f9f2f5`,
    shoorah_2: `#ffc6e4`,
    shoorah_3: `#ff9bcb`,
    shoorah_4: `#f076a0`,
    shoorah_5: `#f05289`,
    shoorah_6: `#bf3261`,
    shoorah_7: `#731c3f`,
    shoorah_8: `#381e23`,

    shoorah_range_1: `accent-[#f9f2f5]`,
    shoorah_range_2: `accent-[#ffc6e4]`,
    shoorah_range_3: `accent-[#ff9bcb]`,
    shoorah_range_4: `accent-[#f076a0]`,
    shoorah_range_5: `accent-[#f05289]`,
    shoorah_range_6: `accent-[#bf3261]`,
    shoorah_range_7: `accent-[#731c3f]`,
    shoorah_range_8: `accent-[#381e23]`,

    shoorah_text_1: `text-[#f9f2f5]`,
    shoorah_text_2: `text-[#ffc6e4]`,
    shoorah_text_3: `text-[#ff9bcb]`,
    shoorah_text_4: `text-[#f076a0]`,
    shoorah_text_5: `text-[#f05289]`,
    shoorah_text_6: `text-[#bf3261]`,
    shoorah_text_7: `text-[#731c3f]`,
    shoorah_text_8: `text-[#381e23]`,

    shoorah_text_hover_1: `hover:text-[#f9f2f5]`,
    shoorah_text_hover_2: `hover:text-[#ffc6e4]`,
    shoorah_text_hover_3: `hover:text-[#ff9bcb]`,
    shoorah_text_hover_4: `hover:text-[#f076a0]`,
    shoorah_text_hover_5: `hover:text-[#f05289]`,
    shoorah_text_hover_6: `hover:text-[#bf3261]`,
    shoorah_text_hover_7: `hover:text-[#731c3f]`,
    shoorah_text_hover_8: `hover:text-[#381e23]`,

    shoorah_bg_1: `bg-[#f9f2f5]`,
    shoorah_bg_2: `bg-[#ffc6e4]`,
    shoorah_bg_3: `bg-[#ff9bcb]`,
    shoorah_bg_4: `bg-[#f076a0]`,
    shoorah_bg_5: `bg-[#f05289]`,
    shoorah_bg_6: `bg-[#bf3261]`,
    shoorah_bg_7: `bg-[#731c3f]`,
    shoorah_bg_8: `bg-[#381e23]`,

    shoorah_fill_1: `fill-[#f9f2f5]`,
    shoorah_fill_2: `fill-[#ffc6e4]`,
    shoorah_fill_3: `fill-[#ff9bcb]`,
    shoorah_fill_4: `fill-[#f076a0]`,
    shoorah_fill_5: `fill-[#f05289]`,
    shoorah_fill_6: `fill-[#bf3261]`,
    shoorah_fill_7: `fill-[#731c3f]`,
    shoorah_fill_8: `fill-[#381e23]`,

    shoorah_bg_hover_1: `hover:bg-[#f9f2f5]`,
    shoorah_bg_hover_2: `hover:bg-[#ffc6e4]`,
    shoorah_bg_hover_3: `hover:bg-[#ff9bcb]`,
    shoorah_bg_hover_4: `hover:bg-[#f076a0]`,
    shoorah_bg_hover_5: `hover:bg-[#f05289]`,
    shoorah_bg_hover_6: `hover:bg-[#bf3261]`,
    shoorah_bg_hover_7: `hover:bg-[#731c3f]`,
    shoorah_bg_hover_8: `hover:bg-[#381e23]`,

    shoorah_border_1: `border-[#f9f2f5]`,
    shoorah_border_2: `border-[#ffc6e4]`,
    shoorah_border_3: `border-[#ff9bcb]`,
    shoorah_border_4: `border-[#f076a0]`,
    shoorah_border_5: `border-[#f05289]`,
    shoorah_border_6: `border-[#bf3261]`,
    shoorah_border_7: `border-[#731c3f]`,
    shoorah_border_8: `border-[#381e23]`,

    shoorah_border_ring_focus_1: `focus:ring-[#f9f2f5]`,
    shoorah_border_ring_focus_2: `focus:ring-[#ffc6e4]`,
    shoorah_border_ring_focus_3: `focus:ring-[#ff9bcb]`,
    shoorah_border_ring_focus_4: `focus:ring-[#f076a0]`,
    shoorah_border_ring_focus_5: `focus:ring-[#f05289]`,
    shoorah_border_ring_focus_6: `focus:ring-[#bf3261]`,
    shoorah_border_ring_focus_7: `focus:ring-[#731c3f]`,
    shoorah_border_ring_focus_8: `focus:ring-[#381e23]`,

    shoorah_border_focus_1: `focus:border-[#f9f2f5]`,
    shoorah_border_focus_2: `focus:border-[#ffc6e4]`,
    shoorah_border_focus_3: `focus:border-[#ff9bcb]`,
    shoorah_border_focus_4: `focus:border-[#f076a0]`,
    shoorah_border_focus_5: `focus:border-[#f05289]`,
    shoorah_border_focus_6: `focus:border-[#bf3261]`,
    shoorah_border_focus_7: `focus:border-[#731c3f]`,
    shoorah_border_focus_8: `focus:border-[#381e23]`,

    shoorah_shadow_1: `shadow-[#f9f2f5]`,
    shoorah_shadow_2: `shadow-[#ffc6e4]`,
    shoorah_shadow_3: `shadow-[#ff9bcb]`,
    shoorah_shadow_4: `shadow-[#f076a0]`,
    shoorah_shadow_5: `shadow-[#f05289]`,
    shoorah_shadow_6: `shadow-[#bf3261]`,
    shoorah_shadow_7: `shadow-[#731c3f]`,
    shoorah_shadow_8: `shadow-[#381e23]`,

    shoorah_inactive_icons: `text-[#9399b1]`,
    strokeColor: `#731c3f`,
    strokeColor2: `#731c3f`,
    strokeColor3: `#000`,
    moodBg: `bg-[#3F2328]`,

    shoorah_focus_gradient: `bg-gradient-to-br from-[#FEE3EE] via-[#FED4E6] via-[#FED4E5] to-[#FFADC1]`,

    tickColor: `#fff`,
    color: `bg-gradient-to-t from-[#f7b5b5] via-[#f97171] to-[#f59e9e]`,
    toggletext: `text-[#da4a4a]`,
    toggle_bg: `bg-[#f1a8a8]`,
    inactiveToggleText: `text-[#9399b2]`,
    inactiveToggleBg: `bg-[]`,
    audioPlayButton: `text-[red]`,
    soundBorder: `border-[#da4a4a]`,
    meButton: `bg-[#e86161]`,
    sidebarBg: `bg-[#fff]`,
    sidebarText: `text-[#da4a4a]`,
    heroGradient: `bg-gradient-to-r from-[#ffd4d4] to-[#ffeded]`,
    heroCircle: `bg-[#fea0a0]`,
    addCircle: `border-[#da4a4a]`,
    addCircleText: `text-[#da4a4a]`,
    shareCircle: `bg-[#da4a4a]`,
    affirBg: `bg-gradient-to-r from-[#ffd4d4] to-[#ffeded]`,
    affirBtns: `bg-[#da4a4a]`,
    gradtitudeImage: `text-[#6b3131]`,
    gratitudeShadow: `shadow-[#664d4d]`,
    chatBotBg: `bg-[#e76161]`,
    chatBotCircle: `bg-[#f3b6b6]`,
    chatNowText: `text-[#e76161]`,
    dailyRitualBg: `bg-[#f3b6b6]`,
    dailyRitualBgDone: `bg-[#eb8888]`,
    heroGradient2: `bg-gradient-to-r from-[#ffd4d4] to-[#ffeded]`,
    borderFocus: `focus:border-[#da4a4a]`,
    borderRingFocus: `focus:ring-[#da4a4a]`,
    addBtnBg: `bg-[#e86161]`,
    imageCardBg: `bg-[#e86160]`,
    confirmMarkBg: `bg-red-100`,
    qMark: `text-[red]`,
    bgPrimary: `bg-[#da4a4a]`,
    textPrimary: `text-[#da4a4a]`,
    textSecondary: `text-[red]`,
    bgSecondary: `bg-[red]`,
    bgSecondaryHover: `hover:bg-[red]`,
    previewImg: `bg-[#eaacac]`,
    previewIcon: `text-[#da4a4a]`,
    previewBorder: `border-[#da4a4a]`,
    errorMsg: `text-red-600`,
    textMsg: `text-[red]`,
    primarytosecondary: `bg-gradient-to-r from-[#da4a4a] to-[red]`,
    bgPrimaryHover: `bg-[#da4a4a]`,
    subsBg: `bg-[#521515]`,
    subsText: `text-[#521515]`,
    subsBorder: `border-[red]`,
    subsDiv: `bg-[#fad9d9]`,
  },
  {
    name: "Dessert",
    shoorah_hard_gradient: desert.gradient_heavy,
    shoorah_medium_gradient: desert.gradient_medium,
    shoorah_light_gradient: desert.gradient_light,
    shoorah_feather_gradient: desert.gradient_feather,
    badge_grad: desert.badge_grad,

    shoorah_audio: dessertAudio,
    shoorah_phone: phoneDessert,

    shoorah_mac: macDessert,

    shoorahMusicShapeLottie: DesertMusicShapeLottie,

    shoorah_circle: desert.shoorah_circle,
    shuruMain: desert.shuruMain,
    shuruMe: meDesert,

    shoorahShapeColor: desert.shapeColor,

    shoorah_hero_personalize: desert.personalize,
    shoorah_hero_notify: desert.notify,
    shoorah_hero_info: desert.info,

    shoorah_home_active: desert.home_active,
    shoorah_home_in_active: desert.home_in_active,
    shoorah_journal_active: desert.journal_active,
    shoorah_journal_in_active: desert.journal_in_active,
    shoorah_rituals_active: desert.rituals_active,
    shoorah_rituals_in_active: desert.rituals_in_active,
    shoorah_restore_active: desert.restore_active,
    shoorah_restore_in_active: desert.restore_in_active,
    shoorah_explore_active: desert.explore_active,
    shoorah_explore_in_active: desert.explore_in_active,

    shoorah_box_shape: desert.box_shape,
    shoorah_star_shape: desert.star_shape,
    shoorah_edge_shape: desert.edge_box,
    shoorah_circle_shape: desert.circle_shape,

    shoorah_add_image_icon: desert.add_image_icon,
    shoorah_title_icon: desert.title_icon,
    shoorah_description_icon: desert.description_icon,

    shuruContent: desert.shuruContent,
    shuruAngry: desert.shuruAngry,
    shuruAnxious: desert.shuruAnxious,
    shuruExcited: desert.shuruExcited,
    shuruHappy: desert.shuruHappy,
    shuruStressed: desert.shuruStressed,
    shuruTired: desert.shuruTired,
    shuruSad: desert.shuruSad,
    shuruSurprised: desert.shuruSurprised,
    shuruStrokeContent: desert.shuruStrokeContent,
    shuruStrokeAngry: desert.shuruStrokeAngry,
    shuruStrokeAnxious: desert.shuruStrokeAnxious,
    shuruStrokeExcited: desert.shuruStrokeExcited,
    shuruStrokeHapshoorahpy: desert.shuruStrokeHappy,
    shuruStrokeTired: desert.shuruStrokeTired,
    shuruStrokeSad: desert.shuruStrokeSad,
    shuruStrokeSurprised: desert.shuruStrokeSurprised,

    shuruContentLotie: desert.shuruContentLotie,
    shuruAngryLotie: desert.shuruAngryLotie,
    shuruAnxiousLotie: desert.shuruAnxiousLotie,
    shuruSurpriseLotie: desert.shuruSurpriseLotie,
    shuruWorriedLotie: desert.shuruWorriedLotie,
    shuruCryLotie: desert.shuruCryLotie,
    shuruHappyLotie: desert.shuruHappyLotie,
    shuruSleepLotie: desert.shuruSleepLotie,
    shuruExcitedLotie: desert.shuruExcitedLotie,

    shuruProfile: desert.shuruProfile,
    shuruLeave: desert.shuruLeave,

    audioBar1: `bg-gradient-to-r from-[#30160a] to-[#703115]`,

    shoorah_1: "#f7f5f0",
    shoorah_2: "#f6e6cf",
    shoorah_3: "#ecc09e",
    shoorah_4: "#e2a280",
    shoorah_5: "#d97f56",
    shoorah_6: "#ca5926",
    shoorah_7: "#703115",
    shoorah_8: "#30160a",

    shoorah_range_1: `accent-[#f7f5f0]`,
    shoorah_range_2: `accent-[#f6e6cf]`,
    shoorah_range_3: `accent-[#ecc09e]`,
    shoorah_range_4: `accent-[#e2a280]`,
    shoorah_range_5: `accent-[#d97f56]`,
    shoorah_range_6: `accent-[#ca5926]`,
    shoorah_range_7: `accent-[#703115]`,
    shoorah_range_8: `accent-[#30160a]`,

    shoorah_text_1: `text-[#f7f5f0]`,
    shoorah_text_2: `text-[#f6e6cf]`,
    shoorah_text_3: `text-[#ecc09e]`,
    shoorah_text_4: `text-[#e2a280]`,
    shoorah_text_5: `text-[#d97f56]`,
    shoorah_text_6: `text-[#ca5926]`,
    shoorah_text_7: `text-[#703115]`,
    shoorah_text_8: `text-[#30160a]`,

    shoorah_text_hover_1: `hover:text-[#f7f5f0]`,
    shoorah_text_hover_2: `hover:text-[#f6e6cf]`,
    shoorah_text_hover_3: `hover:text-[#ecc09e]`,
    shoorah_text_hover_4: `hover:text-[#e2a280]`,
    shoorah_text_hover_5: `hover:text-[#d97f56]`,
    shoorah_text_hover_6: `hover:text-[#ca5926]`,
    shoorah_text_hover_7: `hover:text-[#703115]`,
    shoorah_text_hover_8: `hover:text-[#30160a]`,

    shoorah_bg_1: `bg-[#f7f5f0]`,
    shoorah_bg_2: `bg-[#f6e6cf]`,
    shoorah_bg_3: `bg-[#ecc09e]`,
    shoorah_bg_4: `bg-[#e2a280]`,
    shoorah_bg_5: `bg-[#d97f56]`,
    shoorah_bg_6: `bg-[#ca5926]`,
    shoorah_bg_7: `bg-[#703115]`,
    shoorah_bg_8: `bg-[#30160a]`,

    shoorah_fill_1: `fill-[#f7f5f0]`,
    shoorah_fill_2: `fill-[#f6e6cf]`,
    shoorah_fill_3: `fill-[#ecc09e]`,
    shoorah_fill_4: `fill-[#e2a280]`,
    shoorah_fill_5: `fill-[#d97f56]`,
    shoorah_fill_6: `fill-[#ca5926]`,
    shoorah_fill_7: `fill-[#703115]`,
    shoorah_fill_8: `fill-[#30160a]`,

    shoorah_bg_hover_1: `hover:bg-[#f7f5f0]`,
    shoorah_bg_hover_2: `hover:bg-[#f6e6cf]`,
    shoorah_bg_hover_3: `hover:bg-[#ecc09e]`,
    shoorah_bg_hover_4: `hover:bg-[#e2a280]`,
    shoorah_bg_hover_5: `hover:bg-[#d97f56]`,
    shoorah_bg_hover_6: `hover:bg-[#ca5926]`,
    shoorah_bg_hover_7: `hover:bg-[#703115]`,
    shoorah_bg_hover_8: `hover:bg-[#30160a]`,

    shoorah_border_1: `border-[#f7f5f0]`,
    shoorah_border_2: `border-[#f6e6cf]`,
    shoorah_border_3: `border-[#ecc09e]`,
    shoorah_border_4: `border-[#e2a280]`,
    shoorah_border_5: `border-[#d97f56]`,
    shoorah_border_6: `border-[#ca5926]`,
    shoorah_border_7: `border-[#703115]`,
    shoorah_border_8: `border-[#30160a]`,

    shoorah_border_ring_focus_1: `focus:ring-[#f7f5f0]`,
    shoorah_border_ring_focus_2: `focus:ring-[#f6e6cf]`,
    shoorah_border_ring_focus_3: `focus:ring-[#ecc09e]`,
    shoorah_border_ring_focus_4: `focus:ring-[#e2a280]`,
    shoorah_border_ring_focus_5: `focus:ring-[#d97f56]`,
    shoorah_border_ring_focus_6: `focus:ring-[#ca5926]`,
    shoorah_border_ring_focus_7: `focus:ring-[#703115]`,
    shoorah_border_ring_focus_8: `focus:ring-[#30160a]`,

    shoorah_border_focus_1: `focus:border-[#f7f5f0]`,
    shoorah_border_focus_2: `focus:border-[#f6e6cf]`,
    shoorah_border_focus_3: `focus:border-[#ecc09e]`,
    shoorah_border_focus_4: `focus:border-[#e2a280]`,
    shoorah_border_focus_5: `focus:border-[#d97f56]`,
    shoorah_border_focus_6: `focus:border-[#ca5926]`,
    shoorah_border_focus_7: `focus:border-[#703115]`,
    shoorah_border_focus_8: `focus:border-[#30160a]`,

    shoorah_shadow_1: `shadow-[#f7f5f0]`,
    shoorah_shadow_2: `shadow-[#f6e6cf]`,
    shoorah_shadow_3: `shadow-[#ecc09e]`,
    shoorah_shadow_4: `shadow-[#e2a280]`,
    shoorah_shadow_5: `shadow-[#d97f56]`,
    shoorah_shadow_6: `shadow-[#ca5926]`,
    shoorah_shadow_7: `shadow-[#703115]`,
    shoorah_shadow_8: `shadow-[#30160a]`,

    shoorah_inactive_icons: `text-[#9399b1]`,
    strokeColor: `#703115`,
    strokeColor2: `#703115`,
    strokeColor3: `#d97f56`,
    moodBg: `bg-[#30160a]`,

    shoorah_focus_gradient: `bg-gradient-to-br from-[#FDEEE7] via-[#FEECDB] via-[#FEEDE0] to-[#FFF5EE]`,

    sidebarBg: `bg-[#fff]`,
    tickColor: `#000`,

    color: `bg-gradient-to-t from-[#FEEDE0] via-[#703115] to-[#FFF5EE]`,
    toggletext: `text-[#d97f56]`,
    toggle_bg: `bg-[#f6e6cf]`,
    inactiveToggleText: `text-[#9399b2]`,
    inactiveToggleBg: `bg-[]`,
    audioPlayButton: `text-[#d97f56]`,
    soundBorder: `border-[#d97f56]`,
    meButton: `bg-[#e2a280]`,

    heroGradient: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    heroCircle: `bg-[#b1a0fe]`,
    addCircle: `border-[#4a55da]`,
    addCircleText: `text-[#4a55da]`,
    shareCircle: `bg-[#4a55da]`,
    affirBg: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    affirBtns: `bg-[#4a56da]`,
    gradtitudeImage: `text-[#313b6b]`,
    gratitudeShadow: `shadow-[#4d5266]`,
    chatBotBg: `bg-[#6167e7]`,
    chatBotCircle: `bg-[#b6c0f3]`,
    chatNowText: `text-[#6167e7]`,
    dailyRitualBg: `bg-[#b6c0f3]`,
    dailyRitualBgDone: `bg-[#888beb]`,
    heroGradient2: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    borderFocus: `focus:border-shoorah-primary`,
    borderRingFocus: `focus:ring-shoorah-primary`,
    addBtnBg: `bg-[#e2a280]`,
    imageCardBg: `bg-[#6167e7]`,
    confirmMarkBg: `bg-blue-100`,
    qMark: `text-shoorah-secondary`,
    bgPrimary: `bg-shoorah-primary`,
    textPrimary: `text-shoorah-primary`,
    textSecondary: `text-shoorah-secondary`,
    bgSecondary: `bg-shoorah-secondary`,
    bgSecondaryHover: `hover:bg-shoorah-secondary`,
    previewImg: `bg-[#acb1ea]`,
    previewIcon: `text-[#4a56da]`,
    previewBorder: `border-[#4a56da]`,
    errorMsg: `text-shoorah-primary`,
    textMsg: `text-[red]`,
    primarytosecondary: `bg-gradient-to-r from-shoorah-primary to-shoorah-secondary`,
    bgPrimaryHover: `bg-shoorah-primary`,
    subsBg: `bg-[#151852]`,
    subsText: `text-[#151852]`,
    subsBorder: `border-[blue]`,
    subsDiv: `bg-[#d9dafa]`,
    podsBg: `bg-black`,
    podsCircle: `bg-shoorah-primary`,
    podsText: `text-shoorah-primary`,
  },
  {
    name: "Ocean",
    shoorah_hard_gradient: ocean.gradient_heavy,
    shoorah_medium_gradient: ocean.gradient_medium,
    shoorah_light_gradient: ocean.gradient_light,
    shoorah_feather_gradient: ocean.gradient_feather,
    badge_grad: ocean.badge_grad,

    shoorah_mac: macOcean,
    shoorah_audio: oceanAudio,
    shoorah_phone: phoneOcean,

    shoorahMusicShapeLottie: OceanMusicShapeLottie,
    shuruMain: ocean.shuruMain,

    shoorah_circle: ocean.shoorah_circle,

    shoorahShapeColor: ocean.shapeColor,
    shuruMe: meOcean,

    shoorah_hero_personalize: ocean.personalize,
    shoorah_hero_notify: ocean.notify,
    shoorah_hero_info: ocean.info,

    shoorah_box_shape: ocean.box_shape,
    shoorah_star_shape: ocean.star_shape,
    shoorah_edge_shape: ocean.edge_box,
    shoorah_circle_shape: ocean.circle_shape,

    shoorah_add_image_icon: ocean.add_image_icon,
    shoorah_title_icon: ocean.title_icon,
    shoorah_description_icon: ocean.description_icon,

    shuruContent: ocean.shuruContent,
    shuruAngry: ocean.shuruAngry,
    shuruAnxious: ocean.shuruAnxious,
    shuruExcited: ocean.shuruExcited,
    shuruHappy: ocean.shuruHappy,
    shuruStressed: ocean.shuruStressed,
    shuruTired: ocean.shuruTired,
    shuruSad: ocean.shuruSad,
    shuruSurprised: ocean.shuruSurprised,
    shuruStrokeContent: ocean.shuruStrokeContent,
    shuruStrokeAngry: ocean.shuruStrokeAngry,
    shuruStrokeAnxious: ocean.shuruStrokeAnxious,
    shuruStrokeExcited: ocean.shuruStrokeExcited,
    shuruStrokeHappy: ocean.shuruStrokeHappy,
    shuruStrokeTired: ocean.shuruStrokeTired,
    shuruStrokeSad: ocean.shuruStrokeSad,
    shuruStrokeSurprised: ocean.shuruStrokeSurprised,

    shuruContentLotie: ocean.shuruContentLotie,
    shuruAngryLotie: ocean.shuruAngryLotie,
    shuruAnxiousLotie: ocean.shuruAnxiousLotie,
    shuruSurpriseLotie: ocean.shuruSurpriseLotie,
    shuruWorriedLotie: ocean.shuruWorriedLotie,
    shuruCryLotie: ocean.shuruCryLotie,
    shuruHappyLotie: ocean.shuruHappyLotie,
    shuruSleepLotie: ocean.shuruSleepLotie,
    shuruExcitedLotie: ocean.shuruExcitedLotie,

    shuruProfile: ocean.shuruProfile,
    shuruLeave: ocean.shuruLeave,

    audioBar1: `bg-gradient-to-r from-[#142929] to-[#144f52]`,

    shoorah_1: "#e8fff3",
    shoorah_2: "#acecd0",
    shoorah_3: "#6be0bd",
    shoorah_4: "#7dc4a6",
    shoorah_5: "#21bdad",
    shoorah_6: "#26918f",
    shoorah_7: "#144f52",
    shoorah_8: "#142929",

    shoorah_range_1: `accent-[#e8fff3]`,
    shoorah_range_2: `accent-[#acecd0]`,
    shoorah_range_3: `accent-[#6be0bd]`,
    shoorah_range_4: `accent-[#7dc4a6]`,
    shoorah_range_5: `accent-[#21bdad]`,
    shoorah_range_6: `accent-[#26918f]`,
    shoorah_range_7: `accent-[#144f52]`,
    shoorah_range_8: `accent-[#142929]`,

    shoorah_text_1: `text-[#e8fff3]`,
    shoorah_text_2: `text-[#acecd0]`,
    shoorah_text_3: `text-[#6be0bd]`,
    shoorah_text_4: `text-[#7dc4a6]`,
    shoorah_text_5: `text-[#21bdad]`,
    shoorah_text_6: `text-[#26918f]`,
    shoorah_text_7: `text-[#144f52]`,
    shoorah_text_8: `text-[#142929]`,

    shoorah_text_hover_1: `hover:text-[#e8fff3]`,
    shoorah_text_hover_2: `hover:text-[#acecd0]`,
    shoorah_text_hover_3: `hover:text-[#6be0bd]`,
    shoorah_text_hover_4: `hover:text-[#7dc4a6]`,
    shoorah_text_hover_5: `hover:text-[#21bdad]`,
    shoorah_text_hover_6: `hover:text-[#26918f]`,
    shoorah_text_hover_7: `hover:text-[#144f52]`,
    shoorah_text_hover_8: `hover:text-[#142929]`,

    shoorah_bg_1: `bg-[#e8fff3]`,
    shoorah_bg_2: `bg-[#acecd0]`,
    shoorah_bg_3: `bg-[#6be0bd]`,
    shoorah_bg_4: `bg-[#7dc4a6]`,
    shoorah_bg_5: `bg-[#21bdad]`,
    shoorah_bg_6: `bg-[#26918f]`,
    shoorah_bg_7: `bg-[#144f52]`,
    shoorah_bg_8: `bg-[#142929]`,

    shoorah_fill_1: `fill-[#e8fff3]`,
    shoorah_fill_2: `fill-[#acecd0]`,
    shoorah_fill_3: `fill-[#6be0bd]`,
    shoorah_fill_4: `fill-[#7dc4a6]`,
    shoorah_fill_5: `fill-[#21bdad]`,
    shoorah_fill_6: `fill-[#26918f]`,
    shoorah_fill_7: `fill-[#144f52]`,
    shoorah_fill_8: `fill-[#142929]`,

    shoorah_bg_hover_1: `hover:bg-[#e8fff3]`,
    shoorah_bg_hover_2: `hover:bg-[#acecd0]`,
    shoorah_bg_hover_3: `hover:bg-[#6be0bd]`,
    shoorah_bg_hover_4: `hover:bg-[#7dc4a6]`,
    shoorah_bg_hover_5: `hover:bg-[#21bdad]`,
    shoorah_bg_hover_6: `hover:bg-[#26918f]`,
    shoorah_bg_hover_7: `hover:bg-[#144f52]`,
    shoorah_bg_hover_8: `hover:bg-[#142929]`,

    shoorah_border_1: `border-[#e8fff3]`,
    shoorah_border_2: `border-[#acecd0]`,
    shoorah_border_3: `border-[#6be0bd]`,
    shoorah_border_4: `border-[#7dc4a6]`,
    shoorah_border_5: `border-[#21bdad]`,
    shoorah_border_6: `border-[#26918f]`,
    shoorah_border_7: `border-[#144f52]`,
    shoorah_border_8: `border-[#142929]`,

    shoorah_border_ring_focus_1: `focus:ring-[#e8fff3]`,
    shoorah_border_ring_focus_2: `focus:ring-[#acecd0]`,
    shoorah_border_ring_focus_3: `focus:ring-[#6be0bd]`,
    shoorah_border_ring_focus_4: `focus:ring-[#7dc4a6]`,
    shoorah_border_ring_focus_5: `focus:ring-[#21bdad]`,
    shoorah_border_ring_focus_6: `focus:ring-[#26918f]`,
    shoorah_border_ring_focus_7: `focus:ring-[#144f52]`,
    shoorah_border_ring_focus_8: `focus:ring-[#142929]`,

    shoorah_border_focus_1: `focus:border-[#e8fff3]`,
    shoorah_border_focus_2: `focus:border-[#acecd0]`,
    shoorah_border_focus_3: `focus:border-[#6be0bd]`,
    shoorah_border_focus_4: `focus:border-[#7dc4a6]`,
    shoorah_border_focus_5: `focus:border-[#21bdad]`,
    shoorah_border_focus_6: `focus:border-[#26918f]`,
    shoorah_border_focus_7: `focus:border-[#144f52]`,
    shoorah_border_focus_8: `focus:border-[#142929]`,

    shoorah_shadow_1: `shadow-[#e8fff3]`,
    shoorah_shadow_2: `shadow-[#acecd0]`,
    shoorah_shadow_3: `shadow-[#6be0bd]`,
    shoorah_shadow_4: `shadow-[#7dc4a6]`,
    shoorah_shadow_5: `shadow-[#21bdad]`,
    shoorah_shadow_6: `shadow-[#26918f]`,
    shoorah_shadow_7: `shadow-[#144f52]`,
    shoorah_shadow_8: `shadow-[#142929]`,

    shoorah_inactive_icons: `text-[#9399b1]`,
    strokeColor: `#144f52`,
    strokeColor2: `#144f52`,
    strokeColor3: `#21bdad`,
    moodBg: `bg-[#142929]`,

    shoorah_focus_gradient: `bg-gradient-to-br from-[#F0FEF3] via-[#DBF7F1] via-[#CBF1EE] to-[#C6F8FB]`,

    sidebarBg: `bg-[#fff]`,
    tickColor: `#000`,

    color: `bg-gradient-to-t from-[#DBF7F1] via-[#26918f] to-[#F0FEF3]`,
    toggletext: `text-[#21bdad]`,
    toggle_bg: `bg-[#acecd0]`,
    inactiveToggleText: `text-[#9399b2]`,
    inactiveToggleBg: `bg-[]`,
    audioPlayButton: `text-[#21bdad]`,
    soundBorder: `border-[#21bdad]`,
    meButton: `bg-[#7dc4a6]`,

    heroGradient: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    heroCircle: `bg-[#b1a0fe]`,
    addCircle: `border-[#4a55da]`,
    addCircleText: `text-[#4a55da]`,
    shareCircle: `bg-[#4a55da]`,
    affirBg: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    affirBtns: `bg-[#4a56da]`,
    gradtitudeImage: `text-[#313b6b]`,
    gratitudeShadow: `shadow-[#4d5266]`,
    chatBotBg: `bg-[#6167e7]`,
    chatBotCircle: `bg-[#b6c0f3]`,
    chatNowText: `text-[#6167e7]`,
    dailyRitualBg: `bg-[#b6c0f3]`,
    dailyRitualBgDone: `bg-[#888beb]`,
    heroGradient2: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    borderFocus: `focus:border-shoorah-primary`,
    borderRingFocus: `focus:ring-shoorah-primary`,
    addBtnBg: `bg-[#e2a280]`,
    imageCardBg: `bg-[#6167e7]`,
    confirmMarkBg: `bg-blue-100`,
    qMark: `text-shoorah-secondary`,
    bgPrimary: `bg-shoorah-primary`,
    textPrimary: `text-shoorah-primary`,
    textSecondary: `text-shoorah-secondary`,
    bgSecondary: `bg-shoorah-secondary`,
    bgSecondaryHover: `hover:bg-shoorah-secondary`,
    previewImg: `bg-[#acb1ea]`,
    previewIcon: `text-[#4a56da]`,
    previewBorder: `border-[#4a56da]`,
    errorMsg: `text-shoorah-primary`,
    textMsg: `text-[red]`,
    primarytosecondary: `bg-gradient-to-r from-shoorah-primary to-shoorah-secondary`,
    bgPrimaryHover: `bg-shoorah-primary`,
    subsBg: `bg-[#151852]`,
    subsText: `text-[#151852]`,
    subsBorder: `border-[blue]`,
    subsDiv: `bg-[#d9dafa]`,
    podsBg: `bg-black`,
    podsCircle: `bg-shoorah-primary`,
    podsText: `text-shoorah-primary`,
  },
  {
    name: "Sun",
    shoorah_hard_gradient: sun.gradient_heavy,
    shoorah_medium_gradient: sun.gradient_medium,
    shoorah_light_gradient: sun.gradient_light,
    shoorah_feather_gradient: sun.gradient_feather,
    shoorah_mac: macSun,
    shoorah_phone: phoneSun,

    shoorah_audio: sunAudio,
    badge_grad: sun.badge_grad,

    shoorahMusicShapeLottie: SunMusicShapeLottie,
    shuruMain: sun.shuruMain,
    shuruMe: meSun,

    shoorah_circle: sun.shoorah_circle,

    shoorahShapeColor: sun.shapeColor,

    shoorah_hero_personalize: sun.personalize,
    shoorah_hero_notify: sun.notify,
    shoorah_hero_info: sun.info,

    shoorah_box_shape: sun.box_shape,
    shoorah_star_shape: sun.star_shape,
    shoorah_edge_shape: sun.edge_box,
    shoorah_circle_shape: sun.circle_shape,

    shoorah_add_image_icon: sun.add_image_icon,
    shoorah_title_icon: sun.title_icon,
    shoorah_description_icon: sun.description_icon,

    shuruContent: sun.shuruContent,
    shuruAngry: sun.shuruAngry,
    shuruAnxious: sun.shuruAnxious,
    shuruExcited: sun.shuruExcited,
    shuruHappy: sun.shuruHappy,
    shuruStressed: sun.shuruStressed,
    shuruTired: sun.shuruTired,
    shuruSad: sun.shuruSad,
    shuruSurprised: sun.shuruSurprised,
    shuruStrokeContent: sun.shuruStrokeContent,
    shuruStrokeAngry: sun.shuruStrokeAngry,
    shuruStrokeAnxious: sun.shuruStrokeAnxious,
    shuruStrokeExcited: sun.shuruStrokeExcited,
    shuruStrokeHappy: sun.shuruStrokeHappy,
    shuruStrokeTired: sun.shuruStrokeTired,
    shuruStrokeSad: sun.shuruStrokeSad,
    shuruStrokeSurprised: sun.shuruStrokeSurprised,

    shuruContentLotie: sun.shuruContentLotie,
    shuruAngryLotie: sun.shuruAngryLotie,
    shuruAnxiousLotie: sun.shuruAnxiousLotie,
    shuruSurpriseLotie: sun.shuruSurpriseLotie,
    shuruWorriedLotie: sun.shuruWorriedLotie,
    shuruCryLotie: sun.shuruCryLotie,
    shuruHappyLotie: sun.shuruHappyLotie,
    shuruSleepLotie: sun.shuruSleepLotie,
    shuruExcitedLotie: sun.shuruExcitedLotie,

    shuruProfile: sun.shuruProfile,
    shuruLeave: sun.shuruLeave,

    audioBar1: `bg-gradient-to-r from-[#362e1c] to-[#967836]`,

    shoorah_1: "#ffffd6",
    shoorah_2: "#fff2b0",
    shoorah_3: "#ffe873",
    shoorah_4: "#ffdb42",
    shoorah_5: "#edcc12",
    shoorah_6: "#d9b230",
    shoorah_7: "#967836",
    shoorah_8: "#362e1c",

    shoorah_range_1: `accent-[#ffffd6]`,
    shoorah_range_2: `accent-[#fff2b0]`,
    shoorah_range_3: `accent-[#ffe873]`,
    shoorah_range_4: `accent-[#ffdb42]`,
    shoorah_range_5: `accent-[#edcc12]`,
    shoorah_range_6: `accent-[#d9b230]`,
    shoorah_range_7: `accent-[#967836]`,
    shoorah_range_8: `accent-[#362e1c]`,

    shoorah_text_1: `text-[#ffffd6]`,
    shoorah_text_2: `text-[#fff2b0]`,
    shoorah_text_3: `text-[#ffe873]`,
    shoorah_text_4: `text-[#ffdb42]`,
    shoorah_text_5: `text-[#edcc12]`,
    shoorah_text_6: `text-[#d9b230]`,
    shoorah_text_7: `text-[#967836]`,
    shoorah_text_8: `text-[#362e1c]`,

    shoorah_text_hover_1: `hover:text-[#ffffd6]`,
    shoorah_text_hover_2: `hover:text-[#fff2b0]`,
    shoorah_text_hover_3: `hover:text-[#ffe873]`,
    shoorah_text_hover_4: `hover:text-[#ffdb42]`,
    shoorah_text_hover_5: `hover:text-[#edcc12]`,
    shoorah_text_hover_6: `hover:text-[#d9b230]`,
    shoorah_text_hover_7: `hover:text-[#967836]`,
    shoorah_text_hover_8: `hover:text-[#362e1c]`,

    shoorah_bg_1: `bg-[#ffffd6]`,
    shoorah_bg_2: `bg-[#fff2b0]`,
    shoorah_bg_3: `bg-[#ffe873]`,
    shoorah_bg_4: `bg-[#ffdb42]`,
    shoorah_bg_5: `bg-[#edcc12]`,
    shoorah_bg_6: `bg-[#d9b230]`,
    shoorah_bg_7: `bg-[#967836]`,
    shoorah_bg_8: `bg-[#362e1c]`,

    shoorah_fill_1: `fill-[#ffffd6]`,
    shoorah_fill_2: `fill-[#fff2b0]`,
    shoorah_fill_3: `fill-[#ffe873]`,
    shoorah_fill_4: `fill-[#ffdb42]`,
    shoorah_fill_5: `fill-[#edcc12]`,
    shoorah_fill_6: `fill-[#d9b230]`,
    shoorah_fill_7: `fill-[#967836]`,
    shoorah_fill_8: `fill-[#362e1c]`,

    shoorah_bg_hover_1: `hover:bg-[#ffffd6]`,
    shoorah_bg_hover_2: `hover:bg-[#fff2b0]`,
    shoorah_bg_hover_3: `hover:bg-[#ffe873]`,
    shoorah_bg_hover_4: `hover:bg-[#ffdb42]`,
    shoorah_bg_hover_5: `hover:bg-[#edcc12]`,
    shoorah_bg_hover_6: `hover:bg-[#d9b230]`,
    shoorah_bg_hover_7: `hover:bg-[#967836]`,
    shoorah_bg_hover_8: `hover:bg-[#362e1c]`,

    shoorah_border_1: `border-[#ffffd6]`,
    shoorah_border_2: `border-[#fff2b0]`,
    shoorah_border_3: `border-[#ffe873]`,
    shoorah_border_4: `border-[#ffdb42]`,
    shoorah_border_5: `border-[#edcc12]`,
    shoorah_border_6: `border-[#d9b230]`,
    shoorah_border_7: `border-[#967836]`,
    shoorah_border_8: `border-[#362e1c]`,

    shoorah_border_ring_focus_1: `focus:ring-[#ffffd6]`,
    shoorah_border_ring_focus_2: `focus:ring-[#fff2b0]`,
    shoorah_border_ring_focus_3: `focus:ring-[#ffe873]`,
    shoorah_border_ring_focus_4: `focus:ring-[#ffdb42]`,
    shoorah_border_ring_focus_5: `focus:ring-[#edcc12]`,
    shoorah_border_ring_focus_6: `focus:ring-[#d9b230]`,
    shoorah_border_ring_focus_7: `focus:ring-[#967836]`,
    shoorah_border_ring_focus_8: `focus:ring-[#362e1c]`,

    shoorah_border_focus_1: `focus:border-[#ffffd6]`,
    shoorah_border_focus_2: `focus:border-[#fff2b0]`,
    shoorah_border_focus_3: `focus:border-[#ffe873]`,
    shoorah_border_focus_4: `focus:border-[#ffdb42]`,
    shoorah_border_focus_5: `focus:border-[#edcc12]`,
    shoorah_border_focus_6: `focus:border-[#d9b230]`,
    shoorah_border_focus_7: `focus:border-[#967836]`,
    shoorah_border_focus_8: `focus:border-[#362e1c]`,

    shoorah_shadow_1: `shadow-[#ffffd6]`,
    shoorah_shadow_2: `shadow-[#fff2b0]`,
    shoorah_shadow_3: `shadow-[#ffe873]`,
    shoorah_shadow_4: `shadow-[#ffdb42]`,
    shoorah_shadow_5: `shadow-[#edcc12]`,
    shoorah_shadow_6: `shadow-[#d9b230]`,
    shoorah_shadow_7: `shadow-[#967836]`,
    shoorah_shadow_8: `shadow-[#362e1c]`,

    shoorah_inactive_icons: `text-[#9399b1]`,
    strokeColor: `#967836`,
    strokeColor2: `#967836`,
    strokeColor3: `#edcc12`,
    moodBg: `bg-[#362e1c]`,

    shoorah_focus_gradient: `bg-gradient-to-br from-[#FCF6E1] via-[#FFFDE4] via-[#FFF9E1] to-[#F3F6D1]`,

    sidebarBg: `bg-[#fff]`,
    tickColor: `#000`,

    color: `bg-gradient-to-t from-[#FCF6E1] via-[#d9b230] to-[#F3F6D1]`,
    toggletext: `text-[#edcc12]`,
    toggle_bg: `bg-[#fff2b0]`,
    inactiveToggleText: `text-[#9399b2]`,
    inactiveToggleBg: `bg-[]`,
    audioPlayButton: `text-[#edcc12]`,
    soundBorder: `border-[#edcc12]`,
    meButton: `bg-[#ffdb42]`,

    heroGradient: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    heroCircle: `bg-[#b1a0fe]`,
    addCircle: `border-[#4a55da]`,
    addCircleText: `text-[#4a55da]`,
    shareCircle: `bg-[#4a55da]`,
    affirBg: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    affirBtns: `bg-[#4a56da]`,
    gradtitudeImage: `text-[#313b6b]`,
    gratitudeShadow: `shadow-[#4d5266]`,
    chatBotBg: `bg-[#6167e7]`,
    chatBotCircle: `bg-[#b6c0f3]`,
    chatNowText: `text-[#6167e7]`,
    dailyRitualBg: `bg-[#b6c0f3]`,
    dailyRitualBgDone: `bg-[#888beb]`,
    heroGradient2: `bg-gradient-to-r from-[#dad4ff] to-[#edeeff]`,
    borderFocus: `focus:border-shoorah-primary`,
    borderRingFocus: `focus:ring-shoorah-primary`,
    addBtnBg: `bg-[#e2a280]`,
    imageCardBg: `bg-[#6167e7]`,
    confirmMarkBg: `bg-blue-100`,
    qMark: `text-shoorah-secondary`,
    bgPrimary: `bg-shoorah-primary`,
    textPrimary: `text-shoorah-primary`,
    textSecondary: `text-shoorah-secondary`,
    bgSecondary: `bg-shoorah-secondary`,
    bgSecondaryHover: `hover:bg-shoorah-secondary`,
    previewImg: `bg-[#acb1ea]`,
    previewIcon: `text-[#4a56da]`,
    previewBorder: `border-[#4a56da]`,
    errorMsg: `text-shoorah-primary`,
    textMsg: `text-[red]`,
    primarytosecondary: `bg-gradient-to-r from-shoorah-primary to-shoorah-secondary`,
    bgPrimaryHover: `bg-shoorah-primary`,
    subsBg: `bg-[#151852]`,
    subsText: `text-[#151852]`,
    subsBorder: `border-[blue]`,
    subsDiv: `bg-[#d9dafa]`,
    podsBg: `bg-black`,
    podsCircle: `bg-shoorah-primary`,
    podsText: `text-shoorah-primary`,
  },
];

export const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState({});

  const toggleTheme = (themeName) => {
    findTheme(themeName);
  };

  const findTheme = (themeName) => {
    const defaultTheme = themes.find((i) => {
      return i.name == "Shoorah";
    });
    let theme = themes.find((i) => {
      return i.name == themeName;
    });
    if (theme) {
      localStorage.setItem("theme", theme.name);
      return setTheme(theme);
    } else {
      localStorage.setItem("theme", defaultTheme.name);
      return setTheme(defaultTheme);
    }
  };

  useEffect(() => {
    findTheme(localStorage.getItem("theme"));
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
