import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

const BadgeModal = ({ open, setOpen, title, description }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 top-[30%] z-20 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-end sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-2 pb-4 sm:p-3 sm:pt-5 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-1 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="relative flex flex-col items-center justify-evenly">
                        <div className="relative w-full items-center">
                          <p className="P22Mackinac my-2 cursor-default text-center text-xl font-[500] md:text-3xl">
                            {title}
                          </p>
                          <div
                            onClick={() => setOpen(false)}
                            className="absolute top-[0.5rem] right-[1rem] cursor-pointer hover:scale-105"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-x-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                          </div>
                          <hr className="w-full" />
                        </div>

                        <div className="P22Mackinac my-5 text-center text-sm tracking-wide md:text-lg">
                          {description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BadgeModal;
