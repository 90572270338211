import React from "react";
import PropTypes from "prop-types";
import { DOTS, usePagination } from "./usePagination";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { useTheme } from "../../../contents/context/themeContext";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  const { theme } = useTheme();

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="P22Mackinac w-[90vw] rounded-b-[15px] border-gray-200 bg-transparent py-4">
      <ul className="P22Mackinac  mb-0 flex justify-evenly">
        <li
          className={`${currentPage === 1 ? "opacity-50" : "cursor-pointer "} flex`}
          onClick={currentPage !== 1 ? onPrevious : undefined}
        >
          <span
            className={`relative inline-flex items-center rounded-3xl border border-gray-300 bg-white py-2 pl-3 pr-4 text-sm font-medium text-[#0B0F18] hover:bg-gray-50 focus:z-20`}
          >
            <ArrowLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            <span className="P22Mackinac">Previous</span>
          </span>
        </li>

        <div className="hidden space-x-2 sm:flex">
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <span
                  key={index}
                  className={`relative inline-flex items-center bg-white px-3 py-2 text-sm font-medium ${theme.shoorah_text_6}`}
                >
                  ...
                </span>
              );
            }

            return (
              <div key={index} className="flex items-center">
                <li
                  onClick={() => onPageChange(pageNumber)}
                  className={`flex h-[45px] w-[45px] cursor-pointer items-center justify-center ${
                    pageNumber === currentPage
                      ? `${theme.shoorah_border_6} ${theme.shoorah_bg_2} ${theme.shoorah_text_7}`
                      : `border-gray-300 bg-white text-[#606060] hover:bg-gray-100`
                  } rounded-[50%] text-center text-sm font-medium focus:z-20`}
                >
                  <span>{pageNumber}</span>
                </li>
              </div>
            );
          })}
        </div>

        <div className="flex h-[45px] w-[45px] items-center justify-center rounded-[50%] bg-indigo-50 text-sm font-medium text-indigo-600 sm:hidden">
          {currentPage}
        </div>

        <li
          className={`${currentPage === lastPage ? "opacity-75" : "cursor-pointer"} flex`}
          onClick={currentPage !== lastPage ? onNext : undefined}
        >
          <span
            className={`relative inline-flex items-center rounded-3xl border border-gray-300 bg-white py-2 pl-4 pr-3 text-sm font-medium text-[#0B0F18] ${
              currentPage === lastPage ? "text-gray-200" : "text-gray-500"
            } hover:bg-gray-50 focus:z-20`}
          >
            <span className="P22Mackinac">Next</span>
            <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </span>
        </li>
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Pagination;
