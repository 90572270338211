import React, { lazy, memo, Suspense, useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import RoutesFile from "../routes/RouteFile";
import Loader from "../component/common/Loader";
import PropTypes from "prop-types";
import Login from "../container/Login";
import { useAudio } from "../contents/context/audiobar";
import { useTheme } from "../contents/context/themeContext";
import { Api } from "../api";
import { useWebSound } from "../contents/context/webSound";
const NotFound = lazy(() => import("../container/404NotFound"));

const RoutesList = memo(() => {
  const { theme } = useTheme();
  const { setTheme, SoundBar, audioNav, audio } = useAudio();
  const {
    setIsPlaying,
    isPlaying,
    SoundBar: WebSound,
    audio: sound,
  } = useWebSound();

  useEffect(() => {
    setTheme(theme);
  }, []);

  useEffect(() => {
    setIsPlaying(true);
  }, []);

  return (
    <div className=" lg:mb-0">
      <Router>
        {audioNav && <SoundBar sound={audio} />}
        {window.location.pathname == "/personalize" && (
          <WebSound sound={sound} />
        )}
        <Suspense fallback={<Loader />}>
          <Routes>
            {RoutesFile.map((itm, key) => (
              <Route
                key={key}
                exact={itm.exact}
                path={itm.path}
                name={itm.name}
                Component={itm.component}
              />
            ))}
          
            <Route exact path="/" element={<Navigate to="/login" />}></Route>
            <Route exact path="/login" element={<Login />}></Route>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
});

RoutesList.propTypes = {
  isShow: PropTypes.any,
  setShow: PropTypes.any,
};

export default RoutesList;
